import React, { useState, useEffect } from "react";
import ProductCard from "./ProductCard";

function ShoppingPage() {
  const [products, setProducts] = useState([]);

  useEffect(() => {

    fetch('https://api.rudra.shivaksha.com/api/products')
      .then(response => response.json())
      .then(data => {


        setProducts(data.products);
      })
      .catch((error) => console.error("Error fetching products:", error));
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-8">Shopping Page</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product) => (
          <ProductCard key={product.id} product={product} />
        ))}
      </div>
    </div>
  );
}

export default ShoppingPage;
