import React, { useState, useEffect } from 'react';

function OrderReturnHistory() {
  const [returns, setReturns] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');

  useEffect(() => {
    // Fetch data from the API
    fetch('your_api_endpoint_here')
      .then(response => response.json())
      .then(data => setReturns(data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleFilterChange = (filter) => {
    setActiveFilter(filter);
    // You can perform additional actions based on the filter change here
  };

  return (
    <div className="mt-8">
      <section>
        <h2 className="text-lg font-semibold mb-2">Return History</h2>
        <div className="mb-4 flex flex-wrap justify-center space-x-2 md:justify-end md:space-x-4">
          {/* Filter buttons */}
          <button 
            className={`px-3 py-2 rounded-md text-sm focus:outline-none ${activeFilter === 'All' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-700'}`} 
            onClick={() => handleFilterChange('All')}
          >
            All
          </button>
          <button 
            className={`px-3 py-2 rounded-md text-sm focus:outline-none ${activeFilter === 'Requested' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-700'}`} 
            onClick={() => handleFilterChange('Requested')}
          >
            Requested
          </button>
          <button 
            className={`px-3 py-2 rounded-md text-sm focus:outline-none ${activeFilter === 'Completed' ? 'bg-gray-800 text-white' : 'bg-gray-200 text-gray-700'}`} 
            onClick={() => handleFilterChange('Completed')}
          >
            Completed
          </button>
        </div>
        <div className="overflow-x-auto">
          <table className="w-full bg-white rounded-md shadow-md border border-gray-200">
            <thead>
              <tr className="bg-gray-100">
                <th className="px-4 py-2 text-left" style={{ backgroundColor: '#761b0c', color: '#fff' }}>Return ID</th>
                <th className="px-4 py-2 text-left" style={{ backgroundColor: '#761b0c', color: '#fff' }}>Date</th>
                <th className="px-4 py-2 text-left" style={{ backgroundColor: '#761b0c', color: '#fff' }}>Reason</th>
                <th className="px-4 py-2 text-left" style={{ backgroundColor: '#761b0c', color: '#fff' }}>Status</th>
              </tr>
            </thead>
            <tbody>
              {returns.map(returnItem => (
                <tr key={returnItem.id} className="hover:bg-gray-50">
                  <td className="px-4 py-2">{returnItem.id}</td>
                  <td className="px-4 py-2">{returnItem.date}</td>
                  <td className="px-4 py-2">{returnItem.reason}</td>
                  <td className="px-4 py-2">{returnItem.status}</td>
                </tr>
              ))}
              {returns.length === 0 && (
                <tr>
                  <td colSpan="4" className="text-center py-4">No returns found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default OrderReturnHistory;
