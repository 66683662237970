import React, { useState, useEffect } from 'react';
import ProductCard from '../ProductCard';

function Crystal() {
  const [allProducts, setAllProducts] = useState([]);
  const [rudrakshaProducts, setRudrakshaProducts] = useState([]);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const fetchAllProducts = async () => {
    try {
      const response = await fetch('https://api.rudra.shivaksha.com/api/products');
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      console.log('Fetched products:', data.products); // Log fetched products
      setAllProducts(data.products || []); // Ensure allProducts is initialized as an array
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    console.log('All products:', allProducts); // Log allProducts
    const rudrakshaItems = allProducts.filter(product => product.category === 'Crystal');
    console.log('Rudraksha products:', rudrakshaItems); // Log filtered Rudraksha products
    setRudrakshaProducts(rudrakshaItems);
  }, [allProducts]);

  // Placeholder functions
  const handleToggleCart = (productId) => {
    // Implement logic for toggling cart for the given product
    console.log(`Toggled cart for product with ID: ${productId}`);
  };

  const handleToggleWishlist = (productId) => {
    // Implement logic for toggling wishlist for the given product
    console.log(`Toggled wishlist for product with ID: ${productId}`);
  };

  const openPopup = (product) => {
    // Implement logic for opening popup with details of the given product
    console.log(`Opened popup for product:`, product);
  };

  return (
    <div className='mt-14'>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {rudrakshaProducts.map(product => (
          <ProductCard
            key={product.id}
            product={product}
            handleToggleCart={handleToggleCart}
            handleToggleWishlist={handleToggleWishlist}
            openPopup={openPopup}
          />
        ))}
      </div>
    </div>
  );
}

export default Crystal;
