import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  flex-direction: column;
  align-items: center;
  left:-50px;
`;

const ProductCard = styled.div`
  display: flex;
  width: 70%;
  height: 700px; /* Adjusted height */
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  overflow: hidden;
`;


const ImageContainer = styled.div`
  flex: 1;
  overflow: hidden;
`;

const ProductImage = styled.img`
  width: 90%;
  height: 100%;
  object-fit: cover;
`;

const DetailsContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ProductName = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
`;

const Attribute = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
`;

const Description = styled.p`
  font-size: 16px;
`;

const AddToCartButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const DescriptionContainer = styled.div`
  color: #761b0c;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
  position: absolute;
  left: 0;
`;

const Title = styled.h3`
  margin-bottom: 10px;
  color: #761b0c;
`;

const DescriptionText = styled.p`
  margin: 50;
`;

function ProductDetail() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch('https://api.rudra.shivaksha.com/api/products');
        if (!response.ok) {
          throw new Error('Failed to fetch products');
        }
        const data = await response.json();
        const foundProduct = data.products.find(product => product.id === parseInt(productId));
        if (!foundProduct) {
          throw new Error('Product not found');
        }
        setProduct(foundProduct);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching product:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchProducts();

    // Cleanup function
    return () => {
      // Cleanup logic if needed
    };
  }, [productId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <Container>
      <ProductCard>
        <ImageContainer>
          <ProductImage src={`https://api.rudra.shivaksha.com/${product.image_path}`} alt={product.item_name} />
        </ImageContainer>
        <DetailsContainer>
          <div>
            <ProductName>{product.item_name}</ProductName>
            <Attribute><strong>Category:</strong> {product.category}</Attribute>
            <Attribute><strong>Shape and Cut:</strong> {product.shape_and_cut}</Attribute>
            <Attribute><strong>Weight:</strong> {product.weight}</Attribute>
            <Attribute><strong>Color:</strong> {product.color}</Attribute>
            <Attribute><strong>Measurement:</strong> {product.measurement}</Attribute>
            <Attribute><strong>Mount:</strong> {product.mount}</Attribute>
            <Attribute><strong>Natural Faces:</strong> {product.natural_faces}</Attribute>
            <Attribute><strong>X-Rays:</strong> {product.xrays}</Attribute>
            <Attribute><strong>Created Faces:</strong> {product.created_faces}</Attribute>
            <Attribute><strong>Test:</strong> {product.test}</Attribute>
            <Attribute><strong>Comments:</strong> {product.comments}</Attribute>
            <Attribute><strong>Prices:</strong> {product.prices}</Attribute>
            <Attribute><strong>Presiding Deity:</strong> {product.presiding_Deity}</Attribute>
            <Attribute><strong>Ruling Planet:</strong> {product.ruling_planet}</Attribute>
            <Attribute><strong>Beej Mantra:</strong> {product.beej_mantra}</Attribute>
          </div>
          <AddToCartButton>Add to Cart</AddToCartButton>
        </DetailsContainer>
      </ProductCard>
      <DescriptionContainer>
  <Title>Title as per shastra:</Title>
  <DescriptionText><strong>Description:</strong> {product.description}</DescriptionText>
</DescriptionContainer>
    </Container>
  );
}

export default ProductDetail;
