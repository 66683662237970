import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa"; // Import FaSearch icon
import SearchSuggestions from "./SearchSuggestions";
import { Input, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FaFacebook, FaTwitter, FaInstagram, FaShoppingCart,  } from 'react-icons/fa';
import Sidebar from "./Sidebar";
import "./Subnav.css";
import shivakshaLogo from "../../photos/ShivakshaNormalLogo.jpeg";
import { GiHamburgerMenu } from "react-icons/gi";

function Subnav() {
  const [isActive, setIsActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState(""); // State to hold the username
  const [scrolled, setScrolled] = useState(false); // State to track scrolling

  useEffect(() => {
    // Fetch user data from localStorage on component mount
    const userData = JSON.parse(localStorage.getItem("user"));
    if (userData && userData.username) {
      setIsLoggedIn(true);
      setUsername(userData.username);
    }

    // Add scroll event listener to track scrolling
    window.addEventListener("scroll", handleScroll);

    // Cleanup function to remove scroll event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearchChange = (event) => {
    const query = event.target.value.trim().toLowerCase();
    setSearchQuery(query);

    if (query.length === 0) {
      setSearchResults([]);
      return;
    }

    fetch(
      `https://api.rudra.shivaksha.com/api/products?search=${encodeURIComponent(
        query
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        const filteredResults = data.products.filter((result) => {
          const productName = result.item_name.toLowerCase();
          const queryWords = query.split(" ");
          return queryWords.every((word) => productName.includes(word));
        });
        setSearchResults(filteredResults);
      })
      .catch((error) => console.error("Error fetching search results:", error));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleScroll = () => {
    // Check if window.pageYOffset is greater than 10 to determine if the page has been scrolled
    setScrolled(window.pageYOffset > 10);
  };

  return (
    <>

<div className="nav-container">
       
       <div className="social-media">
         <div className="icon">
           <Link to="https://www.facebook.com">
             <FaFacebook />
           </Link>
         </div>
         <div className="icon">
           <Link to="https://www.twitter.com">
             <FaTwitter />
           </Link>
         </div>
         <div className="icon">
           <Link to="https://www.instagram.com">
             <FaInstagram />
           </Link>
         </div>
       </div>

       <ul className={`nav-links`}>
         <li>
           <Link to="/Offer">Offer</Link>
         </li>
         <li>
           <Link to="/wishlist">Wishlist</Link>
         </li>
         <li>
           <Link to="#">Login</Link>
         </li>
       </ul>
     </div>
      <header
        className={`sticky top-10 z-10 ${
          scrolled ? "bg-white shadow-md" : "customBlack"
        } px-2 md:px-5 `}
      >
        <div className="flex justify-between items-center py-2 md:px-8">
          <div className="flex items-center gap-5">
            <Link to="/" className="no-underline">
            <img
            src={shivakshaLogo}
            alt="company logo"
            className="h-12 w-auto ml-2"
          />
            </Link>
            {/* <div className="relative flex items-center">
              <Input 
                className={`px-2 py-1.5 border border-gray-300 rounded-md dark:bg-black-800 dark:text-gray-100 focus:outline-none focus:border-blue-500 ${isActive ? 'active' : ''}`}
                placeholder="Search products..." 
                type="search" 
                value={searchQuery} 
                onChange={handleSearchChange} 
              />
              <div className="search-icon">
                <FaSearch className="search-icon" onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)} />
              </div>
            </div> */}

            {/* wishlist content */}
            <Link
              to="/wishlist"
              className="flex items-center text-gray-800 hover:text-red-500 transition-colors duration-300 no-underline"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mr-1"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M20.25 4.25a5.602 5.602 0 00-7.913 0L12 5.312l-1.337-1.063a5.602 5.602 0 00-7.912 7.912l1.337 1.063L12 21.688l7.912-8.4 1.337-1.062a5.602 5.602 0 000-7.913z"
                />
              </svg>
              <span className="text-lg font-bold">WishList</span>
            </Link>
          </div>

          {/* Conditionally display Welcome message if logged in */}
          {isLoggedIn && (
            <span className="ml-4 text-sm font-semibold hidden md:block">
              Welcome, <span className="text-blue-500">{username}</span>
            </span>
          )}

          <button
            onClick={toggleSidebar}
            className=" bg-white px-[0.6rem] py-[0.30rem] rounded-md"
          >
            <GiHamburgerMenu className=" text-2xl" />
          </button>
        </div>
      </header>
      {searchResults.length > 0 && (
        <SearchSuggestions searchResults={searchResults} />
      )}
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
        isLoggedIn={isLoggedIn}
        setIsLoggedIn={setIsLoggedIn}
      />
    </>
  );
}

export default Subnav;
