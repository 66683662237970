import React, { useState, useEffect } from 'react';
import ProductCard from '../ProductCard';

function Mala() {
  const [allProducts, setAllProducts] = useState([]);
  const [rudrakshaProducts, setRudrakshaProducts] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const fetchAllProducts = async () => {
    try {
      const response = await fetch('https://api.rudra.shivaksha.com/api/products');
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      console.log('Fetched products:', data.products);
      setAllProducts(data.products || []);
      setError('');
    } catch (error) {
      console.error('Error fetching products:', error);
      setError('Failed to fetch products');
    }
  };

  useEffect(() => {
    const rudrakshaItems = allProducts.filter(product => product.category === 'Mala');
    setRudrakshaProducts(rudrakshaItems);
  }, [allProducts]);

  // Placeholder functions
  const handleToggleCart = (productId) => {
    console.log(`Toggled cart for product with ID: ${productId}`);
  };

  const handleToggleWishlist = (productId) => {
    console.log(`Toggled wishlist for product with ID: ${productId}`);
  };

  const openPopup = (product) => {
    console.log(`Opened popup for product:`, product);
  };

  return (
    <div className='mt-14'>
      {error && <p className="text-red-500">{error}</p>}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {rudrakshaProducts.length > 0 ? (
          rudrakshaProducts.map(product => (
            <ProductCard
              key={product.id}
              product={product}
              handleToggleCart={handleToggleCart}
              handleToggleWishlist={handleToggleWishlist}
              openPopup={openPopup}
            />
          ))
        ) : (
          <div className="flex justify-center items-center h-full">
            <p className="text-gray-500">No products available</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default Mala;
