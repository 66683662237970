  import React, { useState, useEffect } from "react";
  import styled from "styled-components";
  import ProductCertificatedCard from "./ProductCertificatedCard";
  const CardContainer = styled.div`
    width: 86mm;
    height: 56mm;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    padding: 10px;
  `;

  const CertificateTitle = styled.h2`
    font-size: 16px;
    margin-bottom: 5px;
  `;

  const CertificateText = styled.p`
    font-size: 12px;
    margin-bottom: 3px;
  `;

  const ProductCertificated = () => {
    const [products, setProducts] = useState([]);

    useEffect(() => {
      const fetchProducts = async () => {
        try {
          const response = await fetch(
            "https://api.rudra.shivaksha.com/api/products"
          );
          if (!response.ok) {
            throw new Error("Failed to fetch products");
          }
          const data = await response.json();
          setProducts(data.products);
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };

      fetchProducts();
    }, []);

    return (
      <div className=" flex flex-col w-[97%] md:w-[65%] my-12 md:mx-auto lg:mx-20 lg:w-[35%] gap-2">
        {products.map((product) => (
          <ProductCertificatedCard key={product.id} product={product} />
        ))}
      </div>
    );
  };

  export default ProductCertificated;
