import React from 'react';

const AboutUs = () => {
  return (
    <div className="h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-2xl p-8 bg-white rounded-lg shadow-lg">
        <h2 className="text-3xl font-bold mb-6">Shivaksha - Founded on 2024</h2>
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Our Mission And Vision</h3>
          <p className="text-lg mb-4">At Shivaksha, our mission is to provide authentic and ethically sourced spiritual items to individuals seeking spiritual growth and harmony. We envision becoming a trusted destination for high-quality spiritual products, fostering spiritual well-being and enlightenment.</p>
        </div>
        <div className="mb-8">
          <h3 className="text-xl font-semibold mb-4">Delivering Original Products</h3>
          <p className="text-lg mb-4">At Shivaksha, we take pride in delivering original products with utmost care. Each item is meticulously sourced and verified for authenticity. We ensure that our products are packaged securely to maintain their quality and integrity during transit.</p>
          <p className="text-lg">Our commitment to providing high-quality spiritual items has earned us the trust of our customers. Shivaksha is a name synonymous with reliability and authenticity in the spiritual product market.</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
