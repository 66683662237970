import React, { useState } from "react";
import Banner from "./Banner";
import ProductCategory from "../Product/ProductCategory";
import FeaturesProduct from "../Product/FeaturesProduct";
import Footer from "../Footer/Footer";
import SubscribePopup from "../Popup/SubscribePopup";
import FAQ from "./FAQ";
import TLRUdraksha from "./TLRUdraksha";
import YoutubePost from "./PostPages/YoutubePost";
import ShoppingPage from "../Product/ShoppingPage";
import WhyChooseUs from "./WhyChooseUs";
import TranslationComponent from "../TranslationComponent";
import GetInTouch from "./GetInTouch";
import CustomerReview from "./CustomerReview";
import TopSoldProduct from "./TopSoldProduct";
import CarouselSlider from "./CarouselSlider";
import MultiitemCarousel from "./MultiitemCarousel";
import ServicesSection from "./ServicesSection";

function Home() {
  const [showTranslationPopup, setShowTranslationPopup] = useState(false);

  const toggleTranslationPopup = () => {
    setShowTranslationPopup(!showTranslationPopup);
  };

  return (
    <div>
      {showTranslationPopup && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
              position: "relative",
            }}
          >
            <button
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              onClick={toggleTranslationPopup}
            >
              Close
            </button>
            {/* <TranslationComponent /> */}
          </div>
        </div>
      )}
<CarouselSlider/>
      {/* <Banner /> */}
      <SubscribePopup />
      <ProductCategory />
      {/* <FeaturesProduct /> */}
      <TLRUdraksha />
      <FAQ />
      <ServicesSection/>
      {/* <WhyChooseUs /> */}
      <MultiitemCarousel/>
      {/* <CustomerReview /> */}
      {/* <GetInTouch /> */}
      <YoutubePost />
      
     
    </div>
  );
}

export default Home;
