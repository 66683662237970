import React from "react";
import { Link } from "react-router-dom";
import QRCode from "qrcode.react";
import logo from '../../photos/ShivakshaNormalLogo.jpeg'
const ProductCertificatedCard = ({ product }) => {
  const certificateURL = `shivalsha.com/certificateDetail/${product.id}`;

  return (
    <div>
      <div className="border-black border-2 bg-slate-300 rounded-md w-full">
        {/* Company logo and details */}
        <div className="flex mt-2 px-2 h-fit">
          <img
            src={logo}
            alt="company logo"
            className="h-12 w-auto ml-2"
          />
          <div className="text-center w-full">
            <b>Shivaksha Testing Lab</b>
            <p>ISO 9001:2024 Certified Lab</p>
          </div>
        </div>
        <div className="h-[0.1rem] bg-slate-700 mb-1"></div>
        {/* Product information section (img, detail, qr) */}
        <div className="flex justify-between mx-2 md:gap-2 items-center">
          <img
            src={`https://api.rudra.shivaksha.com/${product.image_path}`}
            alt={product.item_name}
            className="w-20 h-20 md:w-24 md:h-24 rounded-sm mt-1"
          />
          <div className="flex flex-col w-[50%] pl-2">
            <div>
              <span className="font-bold">Item name: </span>
              {product.item_name}
            </div>
            <div>
              <span className="font-bold">Shape and cut: </span>
              {product.shape_and_cut}
            </div>
            <div>
              <span className="font-bold">Mount: </span>
              {product.mount}
            </div>
            <div>
              <span className="font-bold">Natural Faces:</span>
              {product.natural_faces}
            </div>
            <div>
              <span className="font-bold">Created Faces: </span>
              {product.created_faces}
            </div>
            <div>
              <span className="font-bold">Test By: </span>
              {product.test}
            </div>
          </div>
          {/* Link to certificate detail page */}
          <Link to={certificateURL}>
            <QRCode value={certificateURL} size={80} />
          </Link>
        </div>
        <div>
          <hr />
          <p className="text-center">
            <strong className="font-bold">IDENTIFIED:</strong>{" "}
            {product.category}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProductCertificatedCard;
