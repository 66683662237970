
import React, { useState, useEffect } from 'react';
  import { FaTimes, FaEye } from 'react-icons/fa'; // Importing remove and view icons from Font Awesome
  import axios from 'axios';
  function UserProfileForm() {
    const totalSteps = 3; // Total number of steps
    const [currentStep, setCurrentStep] = useState(1);
    const [userData, setUserData] = useState({
      name: '',
      user_name: '',
      user_email: '',
      dob: '',
      gender: '',
      country: '',
      mobileNumber: '',
      flatHouseNumber: '',
      buildingApartment: '',
      company: '',
      areaStreetSector: '',
      village: '',
      landmark: '',
      pinCode: '',
      townCity: '',
      state: '',
      redemptionMethod: '',
      PaymentMethod: '',
      bankName: '',
      accountNumber: '',
      paypalId: '',
      paytmNumber: '',
      picture: null
    });

    const [profileData, setProfileData] = useState({
      name: '',
      user_name: '',
      user_email: '',
      dob: '',
      gender: ''
      // Add more fields as needed
    });



    const handleChange = (e) => {
      const { name, value } = e.target;
      setUserData(prevUserData => ({
        ...prevUserData,
        [name]: value
      }));
    };
    
    const handlePictureUpload = (e) => {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        setUserData({ ...userData, picture: reader.result });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    };

    const handleRemovePicture = () => {
      setUserData({ ...userData, picture: '' });
    };

    const handleNext = async () => {
      try {
      
        setCurrentStep(currentStep + 1);
      } catch (error) {
        console.error('Error sending user address data:', error);
        // Handle error
        alert('Error sending user address data. Please try again later.');
      }
    };
    

    const handlePrev = () => {
      setCurrentStep(currentStep - 1);
    };

    const handleFileChange = (e) => {
      setUserData({ ...userData, picture: e.target.files[0] });
    };


 
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user.id : null;  
        // Check if userId is null or undefined
        if (!userId) {
          console.error('User ID not found in local storage');
          // Handle the error accordingly, e.g., redirect to login page or display an error message
          return;
        }

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
      
        const formData = new FormData();
        formData.append('image', userData.picture);
    
        // Step 1: Send user profile data
        const userProfileData = {
          user_id: userId,
          picture: userData.picture,
          name: userData.name,
          user_name: userData.user_name,
          user_email: userData.user_email,
          dob: userData.dob,
          gender: userData.gender,
        };
    
        // Send user profile data to the server
        const userProfileResponse = await axios.post('http://localhost:3006/api/user-profile', userProfileData);
    
        // Handle successful user profile creation
        console.log('User profile created successfully:', userProfileResponse.data.profile_id);
    
        // Step 2: Send user address data
        const userAddressData = {
          user_id: userId,
          country: userData.country,
          mobileNumber: userData.mobileNumber,
          flatHouseNumber: userData.flatHouseNumber,
          buildingApartment: userData.buildingApartment,
          company: userData.company,
          areaStreetSector: userData.areaStreetSector,
          village: userData.village,
          landmark: userData.landmark,
          pinCode: userData.pinCode,
          townCity: userData.townCity,
          state: userData.state,
        };
    
        // Send user address data to the server
        const userAddressResponse = await axios.post('http://localhost:3006/api/user-address', userAddressData);
    
        // Handle successful user address creation
        console.log('User address created successfully:', userAddressResponse.data);
    
        // Step 3: Send wallet redemption data
        const walletRedemptionData = {
          user_id: userId,
          redemptionMethod: userData.redemptionMethod,
          PaymentMethod: userData.PaymentMethod,
          bankName: userData.bankName,
          accountNumber: userData.accountNumber,
          paypalId: userData.paypalId,
          paytmNumber: userData.paytmNumber,
        };
    
        // Send wallet redemption data to the server
        const walletRedemptionResponse = await axios.post('http://localhost:3006/api/wallet-redemption', walletRedemptionData);
    
      
        console.log('Wallet redemption created successfully:', walletRedemptionResponse.data);
    
        
        alert('Form submitted successfully!');
      } catch (error) {
        console.error('Error submitting form:', error);
       
        alert('Error submitting form. Please try again later.');
      }
    };
    

    // const fetchProfileData = async () => {
    //   try {
    //     const response = await axios.get(`http://localhost:3006/api/get-user-profile/${userId}`);
    //     setProfileData(response.data); // Update this line to set profileData properly
    //   } catch (error) {
    //     console.error('Error fetching user profile data:', error);
    //   }
    // };
    
  
    // if (userId) {
    //   fetchProfileData();
    // } else {
    //   console.error('User ID not found in local storage');
    // }
  
    // if (!userId) {
    //   console.error('User ID not found in local storage');
    // }

    

    return (
      <>
        <div className="mx-auto px-1 md:px-15 pt-12 md:pt-0 md:mt-10 md:ml-8 md:mr-8 mb-16 " required>
          <h2 className="text-2xl font-bold mb-4 md:text-center font-serif text-blue-800 shadow-md">USER KYC FROM </h2>
    {/* Progress Bar */}
  <div className="mb-4 relative">
    <div className="w-full h-2 bg-gray-300 rounded-full">
      {currentStep === 1 ? (
        <div className="h-full bg-transparent rounded-full transition-width duration-300 ease-in-out w-0" />
      ) : (
        <div className={`h-full bg-${currentStep === totalSteps ? 'green' : 'blue'}-500 rounded-full transition-width duration-300 ease-in-out w-${((currentStep - 1) / (totalSteps - 1)) * 100}`}>
          <span className={`absolute inset-y-0 left-0 pl-2 text-xs text-gray-700 ${currentStep === totalSteps ? 'right-0' : ''}`}>
            {/* {currentStep === totalSteps ? '100%' : `${((currentStep - 1) / (totalSteps - 1)) * 100}%`} */}
          </span>
        </div>
      )}
    </div>
  </div>

  {/* Current Step Indicator */}
  <div className="text-xs text-gray-600 text-center">
    Step {currentStep} of {totalSteps}
  </div>




          {/* Form */}
          <form onSubmit={handleSubmit} className="max-w-lg mx-auto shadow-md p-8 rounded-lg bg-white "required>
            {/* Form Sections */}
            {/* Profile Picture */}
            {/* {currentStep === 1 && (
            <div>
            <label htmlFor="image">Image:</label>
            <input type="file" name="image" onChange={handleFileChange} />
          </div> 
            )} */}

            {/* User Details */}
            {currentStep === 1 && (
              <div className="grid grid-cols-2 md:grid-cols-2 gap-2">
                {/* User Details Section */}
                <div className="mb-4">
                  <label className="block mb-2">Name:</label>
                  <input type="text"  name="name"  value={userData.name || profileData.name} 
                     className="w-full border border-gray-300 rounded-md px-3 py-2" 
                      required 
                      />

                </div>
                <div className="mb-4">
                  <label className="block mb-2">User_Name:</label>
                  <input type="text" name="user_name" value={userData.user_name} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2"required />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">User_Email:</label>
                  <input type="email" name="user_email" value={userData.user_email} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" required/>
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Date of Birth:</label>
                  <input type="date" name="dob" value={userData.dob} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2"required />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Gender:</label>
                  <select name="gender" value={userData.gender} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2"required>
                    <option value="">Select</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              </div>
            )}

            {/* Address Section */}
            {currentStep === 2 && (
              <div className="grid grid-cols-2 md:grid-cols-2 gap-2">
                {/* Address Fields */}
                <div className="mb-4">
                  <label className="block mb-2">Country:</label>
                  <select name="country" value={userData.country} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2">
                    <option value="">Select</option>
                    <option value="USA">USA</option>
                    <option value="Canada">Canada</option>
                    <option value="UK">UK</option>
                    {/* Add more countries as needed */}
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Mobile Number:</label>
                  <input type="tel" name="mobileNumber" value={userData.mobileNumber} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Flat/House Number:</label>
                  <input type="text" name="flatHouseNumber" value={userData.flatHouseNumber} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Building/Apartment:</label>
                  <input type="text" name="buildingApartment" value={userData.buildingApartment} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Company:</label>
                  <input type="text" name="company" value={userData.company} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Area/Street/Sector:</label>
                  <input type="text" name="areaStreetSector" value={userData.areaStreetSector} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Village:</label>
                  <input type="text" name="village" value={userData.village} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Landmark:</label>
                  <input type="text" name="landmark" value={userData.landmark} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Pin Code:</label>
                  <input type="text" name="pinCode" value={userData.pinCode} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">Town/City:</label>
                  <input type="text" name="townCity" value={userData.townCity} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
                <div className="mb-4">
                  <label className="block mb-2">State:</label>
                  <input type="text" name="state" value={userData.state} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
                </div>
              </div>
            )}

          {/* Wallet Redemption Details Section */}
  {currentStep === 3 && (
    <div className="mb-4">
      <label className="block mb-2">Select Redemption Method:</label>
      <select name="redemptionMethod" value={userData.redemptionMethod} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4">
        <option value="">Select Redemption Method</option>
        <option value="bank">Bank Transfer</option>
        <option value="paypal">PayPal</option>
        <option value="paytm">Paytm</option>
      </select>

      {/* Render fields based on selected redemption method */}
      {userData.redemptionMethod === 'bank' && (
        <>
          <div className="mb-4">
            <label className="block mb-2">Bank Name:</label>
            <input type="text" name="bankName" value={userData.bankName} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Account Number:</label>
            <input type="text" name="accountNumber" value={userData.accountNumber} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
          </div>
        </>
      )}

      {userData.redemptionMethod === 'paypal' && (
        <div className="mb-4">
          <label className="block mb-2">PayPal ID:</label>
          <input type="text" name="paypalId" value={userData.paypalId} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
        </div>
      )}

      {userData.redemptionMethod === 'paytm' && (
        <div className="mb-4">
          <label className="block mb-2">Paytm Number:</label>
          <input type="text" name="paytmNumber" value={userData.paytmNumber} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2" />
        </div>
      )}

  <div className="mb-4">
    <h3 className="text-lg font-semibold mb-2">Select Payment Method:</h3>
    <select name="PaymentMethod" value={userData.PaymentMethod} onChange={handleChange} className="w-full border border-gray-300 rounded-md px-3 py-2 mb-4">
      <option value="">Select Payment Method</option>
      <option value="cashOnDelivery">Cash On Delivery</option>
      <option value="onlinePayment">Online Payment</option>
    </select>

    {/* Render redemption details based on selected method */}
    {userData.PaymentMethod === 'cashOnDelivery' && (
      <div>
        {/* Cash On Delivery details */}
        <p className="font-semibold">Cash On Delivery Details:</p>
        <p>Please keep cash ready upon delivery.</p>
      </div>
    )}
    {userData.PaymentMethod === 'onlinePayment' && (
      <div>
        {/* Online Payment details */}
        <p className="font-semibold">Online Payment Details:</p>
        <div className="payment-instructions bg-gray-100 p-4 rounded-md">
          <p className="text-lg text-gray-800">Upon receiving your goods, please proceed with the payment. You'll find our QR code for payment included.</p>
        </div>
      </div>
    )}
  </div>
    </div>
  )}





            {/* Navigation Buttons */}
            <div className="flex justify-between">
              {currentStep > 1 && (
                <button type="button" onClick={handlePrev} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Previous</button>
              )}
              {currentStep < 3 && (
                <button type="button" onClick={handleNext} className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Next</button>
              )}
              {currentStep === 3 && (
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Submit</button>
              )}
            </div>
          </form>
        </div>
      </>
    );
  }

  export default UserProfileForm;
