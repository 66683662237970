import React from "react";
import { Link } from "react-router-dom";

const SearchSuggestions = ({ searchResults }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "calc(11% + 10px)",
        left: "85%",
        transform: "translateX(-50%)",
        width: "20%",
        backgroundColor: "white",
        border: "1.5px solid #ccc",
        borderRadius: "4px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
        zIndex: 10000,
      }}
      className="search-suggestions-container"
    >
      <div className="search-suggestions">
        {searchResults.map((result) => (
          <div
            key={result.id}
            className="search-suggestion"
            style={{ padding: "8px 16px" }}
          >
            {/* Customize how each suggestion is displayed */}
            <Link
              to={`/product/${result.id}`}
              className="suggestion-link"
              style={{
                color: "#333",
                textDecoration: "none",
                transition: "background-color 0.3s",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#f0f0f0";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "inherit";
              }}
            >
              {result.item_name}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchSuggestions;
