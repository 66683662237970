import React, { useState, useEffect } from "react";
import { FaShoppingCart, FaHeart, FaArrowRight } from "react-icons/fa";
import { SlArrowRight } from "react-icons/sl";
import axios from "axios";

const ProductPage = () => {
  const [filter, setFilter] = useState({
    name: "",
    category: "",
    minPrice: "",
    maxPrice: "",
  });

  const [filterOption, setFilterOption] = useState("category");
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseProducts = await axios.get(
          "https://api.rudra.shivaksha.com/api/products"
        );
        const productsData = responseProducts.data.products;
        setProducts(productsData);
        setFilteredProducts(productsData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filteredData = products.filter((product) => {
      return (
        product.item_name.toLowerCase().includes(filter.name.toLowerCase()) &&
        product.category.includes(filter.category) &&
        (!filter.minPrice || product.prices >= parseInt(filter.minPrice)) &&
        (!filter.maxPrice || product.prices <= parseInt(filter.maxPrice))
      );
    });
    setFilteredProducts(
      filteredData.map((product) => ({
        ...product,
        addedToCart: cartItems.some(
          (cartItem) => cartItem.product_id === product.id
        ),
      }))
    );
  }, [filter, products, cartItems]);

  const handleFilterOptionChange = (option) => {
    setFilterOption(option);
    setFilter({
      name: "",
      category: "",
      minPrice: "",
      maxPrice: "",
    });
  };

  const handleToggleCart = async (productId) => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      const userId = userData ? userData.id : null;

      if (!userId) {
        setPopupMessage(
          <span
            style={{
              color: "#dc2626", // Red color
              fontWeight: "bold", // Bold text
              backgroundColor: "#fef2f2", // Light red background
              padding: "8px", // Padding around the text
              borderRadius: "4px", // Rounded corners
              border: "1px solid #dc2626", // Red border
            }}
          >
            User must be logged in to add a product to the cart.
          </span>
        );

        setIsPopupOpen(true);
        return;
      }

      const selectedProduct = products.find(
        (product) => product.id === productId
      );

      if (!selectedProduct) {
        console.error("Selected product not found.");
        return;
      }

      const response = await axios.post(
        `https://api.rudra.shivaksha.com/api/addToCart/${userId}`,
        {
          user_id: userId,
          product_id: productId,
          quantity: 1,
          price: selectedProduct.prices,
          image_path: selectedProduct.image_path,
        }
      );

      if (response.status === 200) {
        const updatedProducts = products.map((item) =>
          item.id === productId ? { ...item, addedToCart: true } : item
        );
        setProducts(updatedProducts);
        console.log("Item added to cart successfully!");
        setPopupMessage(
          <span
            style={{
              color: "#047857", // Green color
              fontWeight: "bold", // Bold text
              backgroundColor: "#d1fae5", // Light green background
              padding: "8px", // Padding around the text
              borderRadius: "4px", // Rounded corners
              border: "1px solid #047857", // Green border
            }}
          >
            Product has been added successfully.
          </span>
        );
        setIsPopupOpen(true);
      } else {
        console.error("Failed to add item to cart");
        setPopupMessage(
          <span
            style={{
              color: "#dc2626", // Red color
              fontWeight: "bold", // Bold text
              backgroundColor: "#fef2f2", // Light red background
              padding: "8px", // Padding around the text
              borderRadius: "4px", // Rounded corners
              border: "1px solid #dc2626", // Red border
            }}
          >
            Failed to add item to cart. Please try again later.
          </span>
        );
        setIsPopupOpen(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const handleWishlist = async (productId) => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      const userId = userData ? userData.id : null;

      if (!userId) {
        setPopupMessage(
          <span
            style={{
              color: "#dc2626", // Red color
              fontWeight: "bold", // Bold text
              backgroundColor: "#fef2f2", // Light red background
              padding: "8px", // Padding around the text
              borderRadius: "4px", // Rounded corners
              border: "1px solid #dc2626", // Red border
            }}
          >
            User must be logged in to add a product to the wishlist.
          </span>
        );
        setIsPopupOpen(true);
        return;
      }

      const selectedProduct = products.find(
        (product) => product.id === productId
      );

      if (!selectedProduct) {
        console.error("Selected product not found.");
        return;
      }

      const response = await axios.post(
        `http://localhost:3006/api/addwishlist/${userId}`,
        {
          user_id: userId,
          product_id: productId,
          image_id: selectedProduct.image_path,
          unit_price_id: selectedProduct.prices,
        }
      );

      if (response.status === 201) {
        console.log("Item added to wishlist successfully!");
        setPopupMessage("Product has been added to the wishlist successfully.");
        setIsPopupOpen(true);
      } else {
        console.error("Failed to add item to wishlist");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


//       const handleWishlist = async (productId) => {
//         try {
//           const userData = JSON.parse(localStorage.getItem("user"));
//           const userId = userData ? userData.id : null;
    
//           if (!userId) {
//             setPopupMessage(
//               <span style={{ 
//                 color: '#dc2626', 
//                 fontWeight: 'bold', 
//                 backgroundColor: '#fef2f2', 
//                 padding: '8px', 
//                 borderRadius: '4px', 
//                 border: '1px solid #dc2626' 
//               }}>
//                 User must be logged in to add a product to the wishlist.
//               </span>
//             );
//             setIsPopupOpen(true);
//             return;
//           }
    
//           const selectedProduct = products.find(product => product.id === productId);
    
//           if (!selectedProduct) {
//             console.error("Selected product not found.");
//             return;
//           }
    
//           const response = await axios.post(
//             `http://localhost:3006/api/addwishlist`, // Updated endpoint
//             {
//               user_id: userId,
//               product_id: productId,
//               image_id: selectedProduct.image_path,
//               unit_price_id: selectedProduct.prices,
//             }
//           );
    
//           if (response.status === 201) {
//             console.log("Item added to wishlist successfully!");
//             setPopupMessage(
//               <span style={{ 
//                 color: '#047857', 
//                 fontWeight: 'bold', 
//                 backgroundColor: '#d1fae5', 
//                 padding: '8px', 
//                 borderRadius: '4px', 
//                 border: '1px solid #047857' 
//               }}>
//                 Product has been added to the wishlist successfully.
//               </span>
//             );
//             setIsPopupOpen(true);
//           } else {
//             console.error("Failed to add item to wishlist");
//           }
//         } catch (error) {
//           console.error("Error:", error);
//         }
//       };
      

  const openPopup = (product) => {
    setSelectedProduct(product);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedProduct(null);
    setPopupMessage("");
  };

  if (loading)
    return (
      <div className="flex items-center justify-center h-screen">
        Loading...
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  // Handler function to update price range
  const handlePriceRangeChange = (min, max) => {
    setFilter({
      ...filter,
      minPrice: min,
      maxPrice: max,
    });
  };

  return (
    <div className="w-[80%] mx-auto my-4 mt-10">
      {" "}
      {/* Added mt-8 for margin-top */}
      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-md p-8 relative">
            <div>{popupMessage}</div>
            <button
              onClick={closePopup}
              className="absolute top-4 right-4 text-gray-600 hover:text-black focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center mb-4  mt-20">
        <div
          className="text-3xl font-bold"
          style={{
            color: "#761b0c",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
          }}
        >
          Available Products
        </div>

        <div className="flex items-center">
          <span className="text-lg mr-2">Filter by:</span>
          <select
            value={filterOption}
            onChange={(e) => handleFilterOptionChange(e.target.value)}
            className="border-2 border-black rounded-md px-2 py-1"
            style={{
              backgroundColor: "#f0f0f0",
              color: "#333",
              fontFamily: "Arial, sans-serif",
              fontWeight: "bold",
              fontSize: "16px",
              marginRight: "10px", // Adjust spacing
            }}
          >
            <option value="category">Category</option>
            <option value="name">Name</option>
            <option value="price">Price Range</option>
          </select>
        </div>
      </div>
      {/* Filter inputs */}
      {filterOption === "category" && (
        <select
          value={filter.category}
          onChange={(e) => setFilter({ ...filter, category: e.target.value })}
          className="px-2 py-1 border-2 border-gray-300 rounded-md my-4"
          style={{
            width: "15%",
            backgroundColor: "#f0f0f0",
            color: "#333",
            fontFamily: "Arial, sans-serif",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        >
          <option value="">All Categories</option>
          {Array.from(new Set(products.map((p) => p.category))).map(
            (category) => (
              <option key={category} value={category}>
                {category}
              </option>
            )
          )}
        </select>
      )}
      {filterOption === "name" && (
        <input
          type="text"
          placeholder="Search by name..."
          value={filter.name}
          onChange={(e) => setFilter({ ...filter, name: e.target.value })}
          className="px-2 py-1 border-2 border-gray-300 rounded-md my-4"
          style={{
            width: "15%",
            backgroundColor: "#f0f0f0",
            color: "#333",
            fontFamily: "Arial, sans-serif",
            fontSize: "14px",
            fontWeight: "bold",
          }}
        />
      )}
      {filterOption === "price" && (
        <>
          <input
            type="number"
            placeholder="Min Price"
            value={filter.minPrice}
            onChange={(e) => {
              const min = e.target.value;
              handlePriceRangeChange(min, filter.maxPrice);
            }}
            className="px-2 py-1 border-2 border-gray-300 rounded-md mr-2 my-4"
            style={{
              backgroundColor: "#f0f0f0",
              color: "#333",
              fontFamily: "Arial, sans-serif",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          />

          <input
            type="number"
            placeholder="Max Price"
            value={filter.maxPrice}
            onChange={(e) => {
              const max = e.target.value;
              handlePriceRangeChange(filter.minPrice, max);
            }}
            className="px-2 py-1 border-2 border-gray-300 rounded-md my-4"
            style={{
              backgroundColor: "#f0f0f0",
              color: "#333",
              fontFamily: "Arial, sans-serif",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          />
        </>
      )}
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {filteredProducts.map((product) => (
          <div
            key={product.id}
            className="border rounded-md overflow-hidden shadow-md relative h-85 transition duration-300 ease-in-out transform hover:scale-105"
          >
            <img
              src={`https://api.rudra.shivaksha.com/${product.image_path}`}
              alt={product.item_name}
              className="w-full h-48 object-cover"
            />
            <div className="px-4 py-2">
              <p className="text-lg font-semibold mb-2">{product.item_name}</p>
              <p className="text-gray-600 mb-2">{product.category}</p>
              <p className="text-gray-800 font-semibold">${product.prices}</p>
            </div>
            <div className="flex items-center justify-between px-4 pb-4">
              <button
                onClick={() => handleToggleCart(product.id)}
                disabled={product.addedToCart} // Disable button if product is already in cart
                className={`flex items-center gap-1 rounded-md px-2 py-[0.25rem] text-sm active:ring-2 active:ring-slate-500 ${
                  product.addedToCart
                    ? "bg-red-800 cursor-not-allowed"
                    : "bg-red-800 text-white"
                } hover:bg-red-900`}
              >
                <FaShoppingCart />{" "}
                <span className="hidden sm:inline">
                  {product.addedToCart ? "Added" : "Add to Cart"}
                </span>
              </button>

              <button
                onClick={() => handleWishlist(product.id)}
                disabled={product.addedToCart} // Disable button if product is already in wishlist
                className={`flex items-center gap-1 rounded-md px-2 py-[0.25rem] text-sm active:ring-2 active:ring-slate-500 ${
                  product.addedToCart
                    ? "bg-red-800 cursor-not-allowed"
                    : "bg-red-800 text-white"
                } hover:bg-red-900`}
              >
                <FaHeart />{" "}
                <span className="hidden sm:inline">
                  {product.addedToCart ? "Added" : "Add to Wishlist"}
                </span>
              </button>

              <button onClick={() => openPopup(product)} className="text-sm">
                <div
                  className={`bg-zinc-900 rounded-md text-white px-2 py-[0.25rem] sm:px-3 sm:py-[0.4rem] bg-red-800 text-white hover:bg-red-900`}
                >
                  <FaArrowRight />
                </div>
              </button>
            </div>
          </div>
        ))}
      </div>
      {/* Details Popup */}
      {isPopupOpen && selectedProduct && (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-md p-8 relative w-full max-w-4xl md:w-[60rem]">
            <div className="flex flex-col md:flex-row md:gap-4">
              <img
                src={`https://api.rudra.shivaksha.com/${selectedProduct.image_path}`}
                alt={selectedProduct.item_name}
                className="w-full md:w-64 h-64 object-cover border mb-4 md:mb-0"
              />
              <div className="flex flex-col">
                <div className="text-2xl font-semibold mb-2">
                  {selectedProduct.item_name}
                </div>
                <div className=" font-semibold">{selectedProduct.category}</div>
                <div className=" grid grid-cols-1 md:grid-cols-3 gap-2 my-2.5">
                  <div>
                    <span className=" font-semibold">Shape:</span>{" "}
                    {selectedProduct.shape_and_cut}
                  </div>
                  <div>
                    <span className=" font-semibold">Weight:</span>{" "}
                    {selectedProduct.weight}{" "}
                  </div>
                  <div>
                    <span className=" font-semibold">Color:</span>{" "}
                    {selectedProduct.color}
                  </div>
                  <div>
                    <span className=" font-semibold">Measurement:</span>{" "}
                    {selectedProduct.measurement}
                  </div>
                  <div>
                    <span className=" font-semibold">Natural Faces:</span>{" "}
                    {selectedProduct.natural_faces}
                  </div>
                  <div>
                    <span className=" font-semibold">X-Ray:</span>{" "}
                    {selectedProduct.xrays}
                  </div>
                  <div>
                    <span className=" font-semibold">Created Faces:</span>{" "}
                    {selectedProduct.created_faces}
                  </div>
                  <div>
                    <span className=" font-semibold">Tests:</span>{" "}
                    {selectedProduct.test}
                  </div>
                  <div>
                    <span className=" font-semibold">Ruling planet:</span>{" "}
                    {selectedProduct.ruling_planet}
                  </div>
                </div>
                <div>
                  <span className=" font-semibold">Price:</span>{" "}
                  {selectedProduct.prices}
                </div>
                <button
                  onClick={() => handleToggleCart(selectedProduct.id)}
                  disabled={selectedProduct.addedToCart} // Disable button if product is already in cart
                  className={`flex items-center gap-1 rounded-md px-2 py-[0.4rem] active:ring-2 active:ring-slate-500 ${
                    selectedProduct.addedToCart
                      ? "bg-gray-400 cursor-not-allowed"
                      : "bg-zinc-900 text-white"
                  }`}
                >
                  <FaShoppingCart />{" "}
                  <div>
                    {selectedProduct.addedToCart ? "Added" : "Add to Cart"}
                  </div>
                </button>
              </div>
            </div>
            <div className=" flex gap-6 justify-end mt-4 md:mt-0">
              <button
                className=" bg-red-500 text-white px-3 py-[0.4rem] rounded-md "
                onClick={closePopup}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
