import React, { useState } from 'react';
import styled from 'styled-components';

const FAQContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px; /* Adjust spacing as needed */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Add text shadow */
`;

const FAQImageLeft = styled.div`
    margin-left: 200px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

    img {
        width: 500px;
        height: 500px;
        border-radius: 8px;
        object-fit: cover;
    }

    @media screen and (max-width: 768px) {
        margin-left: 0;
        margin-bottom: 20px;
    }
`;

const FAQContentContainer = styled.div`
    flex: 1;
`;

const FAQContentRight = styled.div`
    max-width: 1100px;
`;

const FAQItem = styled.div`
    margin-left: 150px;
    margin-top: 40px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    &:hover {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    @media screen and (max-width: 768px) {
        margin-left: 0;
        width: 100%;
    }
`;

const FAQQuestion = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #ddd;
    background-color: #761b1c;
    color: #f0f0f0; /* Lighter text color */
    border-radius: 8px 8px 0 0;
    transition: background-color 0.3s ease;
    width: 100%;
    &:hover {
        background-color: #5d1510;
    }
`;

const FAQAnswer = styled.div`
    padding: 15px;
    display: ${props => props.expanded ? 'block' : 'none'};
    border-radius: 0 0 8px 8px;
    background-color: #f9f9f9;
    width: auto;
`;

const FAQ = () => {
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        if (expandedIndex === index) {
            setExpandedIndex(null);
        } else {
            setExpandedIndex(index);
        }
    };

    return (
        <>
            <TitleWrapper>
                <h2 style={{ color: '#333', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>Frequently Asked Questions</h2>
            </TitleWrapper>
            
            <FAQContainer>
                <FAQImageLeft>
                    <img src="https://nepalirudraksha.com/cdn/shop/files/Designer_30.png?v=1705579971" alt="Rudraksha Image" />
                </FAQImageLeft>
                <FAQContentContainer>
                    <FAQContentRight>
                        {[1, 3, 4].map((index) => (
                            <FAQItem key={index}>
                                <FAQQuestion onClick={() => toggleExpand(index)}>
                                    {index === 1 && <span style={{ fontWeight: 'bold' }}>What is Rudraksha?</span>}
                                    {index === 3 && <span style={{ fontWeight: 'bold' }}>Who Should Wear Rudraksha?</span>}
                                    {index === 4 && <span style={{ fontWeight: 'bold' }}>Why Buy Rudraksha directly from Nepal?</span>}
                                    <span>{expandedIndex === index ? '-' : '+'}</span>
                                </FAQQuestion>
                                <FAQAnswer expanded={expandedIndex === index}>
                                    {index === 1 && (
                                        <>
                                            <p>Rudraksha is the oldest tools used by mankind to attain spiritual and mental enlightenment. Nepa Rudraksha is the largest collector and distributor of premium quality and authentic Rudraksha for 3 generations directly from Nepal. We pioneer the art of energizing the Rudraksha in accordance with the Vedic methods at Lord Pashupatinath temple. Premium Quality, Vedic Energization and 3 generations of experience in prescribing the best Rudraksha has made us the leader in the field of Nepali Rudraksha and Shaligram.</p>
                                        </>
                                    )}
                                    {index === 3 && (
                                        <>
                                            <p>For Generations, Rudraksha has been worn by people from all walks of life irrespective of their genders, religion, or occupation. Rudraksha is considered to be an essential tool to harvest the blessings of Lord Shiva and bring positivity to our lives. From spiritual gurus to business tycoons, Nepali Rudraksha has played a life-changing role and helped individuals realize their true potential. Hence, anyone who has the drive to bring a positive change in their life should invest in themselves through Nepali Rudraksha for maximum effectiveness.</p>
                                        </>
                                    )}
                                    {index === 4 && (
                                        <>
                                            <p>Rudraksha is grown only in Nepal and it is common knowledge that Rudraksha of Nepali Origin is superior in quality and effectiveness. Hence, to avoid mediator fees and inferior quality, customers are advised to buy Rudraksha directly from Nepal through Nepa Rudraksha; The Oldest and Largest Collection of Nepali Rudraksha.</p>
                                        </>
                                    )}
                                </FAQAnswer>
                            </FAQItem>
                        ))}
                    </FAQContentRight>
                </FAQContentContainer>
            </FAQContainer>
        </>
    );
};

export default FAQ;
