import React, { useState } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [showSetPassword, setShowSetPassword] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);

  const handleVerifyEmail = async () => {
    try {
      const response = await axios.post('https://api.rudra.shivaksha.com/api/forgot-password', { email });
      const { message } = response.data;
      setShowCodePopup(true);
      setMessage(message);
    } catch (error) {
      const errorMessage = error.response.data.message;
      setMessage(errorMessage);
    }
  };

  const handleVerifyCode = async () => {
    try {
      const response = await axios.post('http://localhost:3006/api/check-reset-password', { email, resetCode: verificationCode });
      const { message } = response.data;
      setIsCodeCorrect(true);
      setShowSetPassword(true);
      setMessage(message);
    } catch (error) {
      const errorMessage = error.response.data.message;
      setMessage(errorMessage);
    }
  };

  const handleSavePassword = async () => {
    try {
      // Check if newPassword and confirmPassword match
      if (newPassword !== confirmPassword) {
        setMessage('Passwords do not match');
        return;
      }

      // Send request to update password
      const response = await axios.put('http://localhost:3006/api/update-password', { email, newPassword });
      const { message } = response.data;
      setMessage(message);
      // Clear form fields
      setEmail('');
      setNewPassword('');
      setConfirmPassword('');
      setShowSetPassword(false);
      setShowCodePopup(false);
      setIsCodeCorrect(false);
    } catch (error) {
      const errorMessage = error.response.data.error;
      setMessage(errorMessage);
    }
};
  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4">Forgot Password</h2>
      {!showSetPassword && (
        <div className="mb-3">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control mb-3"
            required
          />
          <button
            onClick={handleVerifyEmail}
            className="btn btn-primary btn-block"
          >
            Request to Set New Password
          </button>
        </div>
      )}

      {showCodePopup && (
        <div className="mb-3">
          <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            className="form-control mb-3"
            required
          />
          <button
            onClick={handleVerifyCode}
            className="btn btn-primary btn-block"
          >
            Verify Code
          </button>
        </div>
      )}

      {showSetPassword && isCodeCorrect && (
        <div>
          <input
            type="password"
            placeholder="New password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="form-control mb-3"
            required
          />
          <input
            type="password"
            placeholder="Confirm new password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-control mb-3"
            required
          />
          <button
            onClick={handleSavePassword}
            className="btn btn-primary btn-block"
          >
            Save Password
          </button>
        </div>
      )}

      {message && <p className="text-center text-danger mt-3">{message}</p>}
    </div>
  );
}

export default ForgotPassword;