import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarWrapper = styled.nav`
  position: fixed;
  top: 40px;
  right: ${(props) => (props.isOpen ? "0" : "-300px")};
  width: 300px;
  height: 100%;
  background-color: #f4f4f4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  z-index: 999;
`;

const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: #ddd;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
`;

const SidebarLinks = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1rem 0;
`;

const SidebarLinkItem = styled.li`
  margin-bottom: 0.5rem;
`;

const SidebarLink = styled(Link)`
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none;
  color: #333;

  &:hover {
    background-color: #ccc;
  }
`;

const Footer = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AuthLinks = styled.div``;

const AuthLink = styled(Link)`
  margin-right: 1rem;
  text-decoration: none;
  color: #333;

  &:hover {
    text-decoration: underline;
  }
`;

const LoginButton = styled.button`
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
`;

function Sidebar({ isOpen, toggleSidebar, isLoggedIn, setIsLoggedIn }) {
  useEffect(() => {
    const fetchUserData = () => {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (userData && userData.id) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
      }
    };

    fetchUserData();

    const interval = setInterval(fetchUserData, 500);

    return () => clearInterval(interval);
  }, [setIsLoggedIn]);

  const handleLogout = () => {
    localStorage.removeItem("user");
    setIsLoggedIn(false);
    alert("Logout successful!");
  };

  const handleLinkClick = () => {
    toggleSidebar();
  };

  return (
    <SidebarWrapper isOpen={isOpen}>
      <SidebarHeader>
        <h3>Menu</h3>
        <CloseButton onClick={toggleSidebar}>&times;</CloseButton>
      </SidebarHeader>
      <SidebarLinks>
      <SidebarLinkItem>
          <SidebarLink to="/user-account" onClick={handleLinkClick}>
          user-account
          </SidebarLink>
        </SidebarLinkItem>
        <SidebarLinkItem>
          <SidebarLink to="/CartPage" onClick={handleLinkClick}>
            User-Cart
          </SidebarLink>
        </SidebarLinkItem>
        <SidebarLinkItem>
          <SidebarLink to="/blog" onClick={handleLinkClick}>
            Blog
          </SidebarLink>
        </SidebarLinkItem>
        <SidebarLinkItem>
          <SidebarLink to="/About-us" onClick={handleLinkClick}>
            About
          </SidebarLink>
        </SidebarLinkItem>
      </SidebarLinks>
      <Footer>
        <SidebarLinkItem className=" mb-4 list-none">
          <AuthLinks>
            {isLoggedIn ? (
              <LoginButton onClick={handleLogout}>Sign Out</LoginButton>
            ) : (
              <>
                <AuthLink to="/login">Log In</AuthLink>
                <AuthLink to="/sign-up">Register</AuthLink>
              </>
            )}
          </AuthLinks>
        </SidebarLinkItem>
      </Footer>
    </SidebarWrapper>
  );
}

export default Sidebar;
