import React from 'react';
import { Button, Card, CardContent } from '@material-ui/core';
import styled, { keyframes } from 'styled-components';

// Keyframes for CSS animations
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components for animations
const ProductSection = styled.section`
  margin-bottom: 100px;
`;

const ProductTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 2rem;
  animation: ${fadeInUp} 1s ease forwards;
`;

const ProductGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
`;

const ProductCard = styled(Card)`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  animation: ${fadeInUp} 1s ease forwards;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ProductCardContent = styled(CardContent)`
  text-align: center;
`;

const ProductImage = styled.img`
  width: 100%;
`;

const AddToCartButton = styled(Button)`
  margin-top: 10px;
  width: 100%;
`;

function FeaturesProduct() {
  return (
    <ProductSection>
      <ProductTitle>Top Sold Products</ProductTitle>
      <ProductGrid>
        <ProductCard>
          <ProductCardContent>
            <ProductImage src="/placeholder.svg" alt="Rudraksha Mala" />
            <div>Rudraksha Mala</div>
            <div>₹49.99</div>
            <AddToCartButton variant="contained" color="761b0c">
              Add to Cart
            </AddToCartButton>
          </ProductCardContent>
        </ProductCard>
        <ProductCard>
          <ProductCardContent>
            <ProductImage src="/placeholder.svg" alt="Amethyst Stone" />
            <div>Amethyst Stone</div>
            <div>₹79.99</div>
            <AddToCartButton variant="contained" color="761b0c">
              Add to Cart
            </AddToCartButton>
          </ProductCardContent>
        </ProductCard>
      </ProductGrid>
    </ProductSection>
  );
}

export default FeaturesProduct;
