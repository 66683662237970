import React from 'react';
import { FaEnvelope, FaLink, FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa';
import './GetInTouch.css'; // Import your styles

const GetInTouch = () => {
    return (
        <div className="h-full flex flex-col justify-center items-center bg-gray-100">
            <div className="container max-w-4xl mx-auto flex flex-col md:flex-row">
                <div className="map w-full md:w-1/2 px-4">
                    <h2 className="text-2xl font-bold mb-4">More Methods</h2>
                    <div className="mb-4">
                        <iframe src="https://snazzymaps.com/embed/2369" title="Map" className="w-full h-64" />
                    </div>
                </div>
                <div className="form w-full md:w-1/2 px-4">
                    <h1 className="text-2xl font-bold mb-4">Get In Touch</h1>
                    <form>
                        <div className="mb-4">
                            <input type="text" placeholder="Your Name" name="name" id="name" className="w-full border border-gray-300 rounded-md px-4 py-2" />
                        </div>
                        <div className="mb-4">
                            <input type="email" placeholder="Your Email" name="email" id="email" className="w-full border border-gray-300 rounded-md px-4 py-2" />
                        </div>
                        <div className="mb-4">
                            <textarea placeholder="Your Message" name="message" id="message" className="w-full border border-gray-300 rounded-md px-4 py-2"></textarea>
                        </div>
                        <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-md">Send Email</button>
                    </form>
                </div>
            </div>
            <div className="mt-8 flex justify-between items-center w-full max-w-4xl px-4">
                <div className="text-left">
                    <h3 className="text-xl font-bold mb-2">Email</h3>
                    <div className="flex items-center mb-4">
                        <FaEnvelope className="w-6 h-6 mr-2" />
                        <a href="mailto:info@shivaksha.com" className="text-blue-500">info@shivaksha.com</a>
                    </div>
                </div>
                <div className="text-right">
                    <h3 className="text-xl font-bold mb-2">Connect</h3>
                    <div className="flex items-center">
                        <a href="#" className="mr-2 text-blue-500" target="_blank">
                            <FaLink className="w-6 h-6" />
                        </a>
                        <a href="#" className="mr-2 text-blue-500" target="_blank">
                            <FaFacebook className="w-6 h-6" />
                        </a>
                        <a href="#" className="mr-2 text-blue-500" target="_blank">
                            <FaInstagram className="w-6 h-6" />
                        </a>
                        <a href="#" className="mr-2 text-blue-500" target="_blank">
                            <FaYoutube className="w-6 h-6" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetInTouch;
