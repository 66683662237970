import React, { useState } from 'react';

function AstroMitesh() {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [customQuestion, setCustomQuestion] = useState('');
  const [totalFees, setTotalFees] = useState(1000);

  const questions = [
    "मेरे जीवन का उद्देश्य क्या है?",
    "मेरा भविष्य कैसा होगा?",
    "मेरे पारिवारिक जीवन का दृष्टिकोण क्या है?",
    "मेरे करियर के लिए उपाय क्या हैं?",
    "क्या मेरी विवाहित जीवन में सुख-शांति होगी?",
    "मेरे धन लाभ का स्थिति क्या है?",
    "मेरी स्वास्थ्य स्थिति कैसी रहेगी?",
    "क्या मैं विदेश यात्रा करूँ?",
    "क्या मैं विदेश में बसे रहूँ?",
    "मेरे धर्म सम्बंधित क्षेत्र में क्या योग हैं?",
    "क्या मैं धार्मिक काम करूँ?",
    "क्या मैं किसी धर्म या धार्मिक संगठन का समर्थन करूँ?",
    "मेरे विचारों और दृष्टिकोण का विवरण दें।",
    "क्या मेरे विचारों में प्रेरणा होगी?",
    "क्या मैं साहित्यिक या कला क्षेत्र में सफल हो पाऊंगा?",
    "क्या मेरा प्रेम संबंध स्थायी होगा?",
    "मेरी शादी कब तक होगी?",
    "क्या मेरे विवाहित जीवन में समस्याएं आएंगी?",
    "मेरे बच्चों का क्या भविष्य होगा?",
    "मेरे भाग्य का उपाय क्या है?",
    "क्या मैं राजनीतिक क्षेत्र में जाऊं?",
    "क्या मुझे सरकारी नौकरी मिलेगी?",
    "क्या मैं व्यवसाय में सफल हो पाऊंगा?",
    "मेरी आर्थिक स्थिति कैसी रहेगी?",
    "क्या मुझे बाहरी आर्थिक सहारा मिलेगा?",
    "क्या मैं किसी धार्मिक गतिविधि का हिस्सा बनूं?",
    "क्या मैं किसी धार्मिक संगठन का समर्थन करूं?",
    "मेरे रोजगार से संबंधित क्षेत्र में क्या योग हैं?",
    "मेरा संतान कितना होगा?",
    "मेरा विवाह किस उम्र में होगा?",
    "क्या मैं धन लाभ करूंगा?",
    "क्या मैं यात्रा करूंगा?",
    "क्या मुझे धर्मिक गतिविधियों में शामिल होना चाहिए?",
    "क्या मेरा भविष्य मुझे विदेश में बसे रहने का मौका देगा?",
    "मेरे जीवन का मुख्य उद्देश्य क्या है?",
    "क्या मैं सरकारी नौकरी के लिए आवेदन कर सकता हूँ?",
    "मेरे जीवन में कौन-कौन से संघर्ष होंगे?",
    "क्या मैं व्यवसाय कर सकता हूँ?",
    "मेरा प्रेम संबंध किस दिशा में जा रहा है?",
    "क्या मेरी विवाहित जीवन में सुख-शांति रहेगी?",
    "मेरे धर्म संबंधित क्षेत्र में क्या योग हैं?"
  ];

  const handleQuestionSelection = (e) => {
    const selectedQuestion = e.target.value;
    setSelectedQuestions([...selectedQuestions, selectedQuestion]);
    setTotalFees(totalFees + 5);
  };

  const handleCustomQuestionChange = (e) => {
    setCustomQuestion(e.target.value);
  };

  const handleCustomQuestionSubmit = () => {
    if (customQuestion.trim() !== '') {
      setSelectedQuestions([...selectedQuestions, customQuestion]);
      setCustomQuestion('');
      setTotalFees(totalFees + 5);
    }
  };

  return (
    <div className=" mx-auto p-4 mt-32 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4 text-center">Astrology Consultation</h1>
      <div className="flex">
        <div className="w-full pr-4">
          <p className="mb-2">Select or enter your questions:</p>
          <select
            value={customQuestion}
            onChange={handleQuestionSelection}
            className="border border-gray-300 px-2 py-1 rounded w-full mt-2 focus:outline-none focus:ring focus:border-blue-500"
          >
            <option value="">Select a question</option>
            {questions.map((question, index) => (
              <option key={index} value={question}>{question}</option>
            ))}
          </select>
          <div className="mt-4">
            <input
              type="text"
              value={customQuestion}
              onChange={handleCustomQuestionChange}
              placeholder="Enter your custom question"
              className="border border-gray-300 px-2 py-1 rounded w-full focus:outline-none focus:ring focus:border-blue-500"
            />
            <button
              onClick={handleCustomQuestionSubmit}
              className="mt-2 bg-blue-500 text-white px-4 py-1 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-500"
            >
              Add
            </button>
          </div>
        </div>
        <div className="w-full pl-4">
          <p className="mb-2">Selected Questions:</p>
          <div className="border border-gray-300 p-2 rounded-lg h-48 overflow-y-auto">
            <ul className="list-disc pl-4 mt-2">
              {selectedQuestions.map((question, index) => (
                <li key={index}>{question}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4">
        <div className="mb-4">
          <p>Total Fees: INR {totalFees}</p>
        </div>
        <div>
          <button className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring focus:border-green-700">
            Pay Now
          </button>
        </div>
      </div>
    </div>
  );
  
  
  
    }

    export default AstroMitesh;
