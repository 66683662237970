import React, { useState, useEffect } from 'react';
import axios from 'axios';

function OrderHistory() {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [filter, setFilter] = useState(''); // State to hold the selected filter

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (!userData || !userData.id) {
          console.error('User ID not found. Please make sure the user is authenticated.');
          setIsLoggedIn(false);
          setIsLoading(false);
          return;
        }

        setIsLoggedIn(true);
        const userId = userData.id;
        const response = await axios.get(`http://localhost:3006/api/allorderdata/${userId}`);
        setOrders(response.data);
        setIsLoading(false); // Set loading state to false after data is fetched
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false); // Set loading state to false if there's an error
      }
    };

    checkAuthentication();
  }, []); // Fetch data only on component mount


  // Define filteredOrders based on the selected filter
  const filteredOrders = filter
    ? orders.filter(order => order.status === filter)
    : orders;

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setSelectedStatus(order.status); // Initialize selected status with the order's current status
    setIsPopupOpen(true);

    // Fetch order details for the selected order
    fetch(`http://localhost:3006/api/orderDetails/${order.order_id}`)
      .then(response => response.json())
      .then(data => {
        setSelectedOrder(prevState => ({
          ...prevState,
          order_details: data
        }));
      })
      .catch(error => console.error('Error fetching order details:', error));
  };

  const closePopup = () => {
    setIsPopupOpen(false);
    setSelectedOrder(null);
  };

  const handleStatusChange = () => {
    // Update the status of the selected order in the database
    fetch('http://localhost:3006/api/update-order-status', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orderId: selectedOrder.order_id,
        newStatus: selectedStatus,
      }),
    })
      .then(response => {
        if (response.ok) {
          console.log('Order status updated successfully');
          closePopup(); // Close the popup after updating the status
        } else {
          console.error('Failed to update order status');
        }
      })
      .catch(error => console.error('Error updating order status:', error));
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

 
  return (
    <div className="mx-auto p-4">

      {/* Filter dropdown */}
      <select value={filter} onChange={handleFilterChange} className="block mb-4 p-2 border border-gray-300 rounded-md">
        <option value="">All</option>
        <option value="Pending">Pending</option>
        <option value="Processing">Processing</option>
        <option value="Dispatched">Dispatched</option>
        <option value="Delivered">Delivered</option>
      </select>
      {/* Check if there are no filtered orders */}
      {filteredOrders.length === 0 && (
        <p className="text-red-500">There is no data in this filter.</p>
      )}
      <ul>
        {/* Render filtered orders */}
        {[...new Set(orders.map(order => order.order_id))].map(orderId => {
          const order = orders.find(order => order.order_id === orderId);
          // Filter orders based on the selected filter
          if (filter && order.status !== filter) return null;
          return (
            
            <li key={orderId} onClick={() => handleOrderClick(order)} className="flex justify-between cursor-pointer bg-gray-100 p-2 mb-2 rounded transition duration-300 hover:shadow-lg">
              <div>
                <div className="text-lg font-semibold">Name: {order.name}</div>
                <div className="text-sm">Order ID: {order.order_id}</div>
                <div>Amount: ${order.total_amount}</div>
                <div>Status: {order.status}</div>
              </div>
              <button className="text-sm bg-blue-500 hover:bg-blue-600 text-white font-semibold py-1 px-3 rounded" onClick={(e) => { e.stopPropagation(); handleOrderClick(order); }}>Req. Return</button>
            </li>
          );
        })}
      </ul>

      {isPopupOpen && selectedOrder && (
  <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-900 bg-opacity-50">
    <div className="bg-white p-8 rounded-lg shadow-lg max-w-3xl overflow-y-auto" id="selected-order-details">
  

      <h3 className="text-xl font-bold mb-4">Selected Order Details</h3>
      <div className="mb-6">
        <div className="font-semibold">Name: {selectedOrder.name}</div>
        <div>Amount: ${selectedOrder.total_amount}</div>
        {/* Render additional order details */}
        <div>Address: {selectedOrder.address}</div>
        <div>Payment Method: {selectedOrder.payment_method}</div>
      </div>
      {/* Render shipping details */}
      <div className="mb-6">
        <h4 className="text-lg font-bold mb-2">Shipping Details</h4>
        <div>Street/Flat: {selectedOrder.street_flat}</div>
        <div>Pin Code: {selectedOrder.pin_code}</div>
        <div>Email: {selectedOrder.email}</div>
        <div>Phone Number: {selectedOrder.phone_number || 'N/A'}</div>
      </div>
      {/* Render products */}
      <h4 className="text-lg font-bold mb-2">Products</h4>
      
      {selectedOrder.order_details && selectedOrder.order_details.map(product => (
        <div key={product.product_id} className="mb-4 border-b pb-4">
          <div>Product Name: {product.product_name}</div>
          <div>Quantity: {product.quantity}</div>
          <div>Price: ${product.price}</div>
          <div>Image Path: {product.image_path}</div>
        </div>
      ))}
      {/* Status Change Dropdown */}
      <div className="mb-6">
        <h4 className="text-lg font-bold mb-2">Change Status</h4>
        <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)} className="block w-full p-2 border border-gray-300 rounded-md">
          <option value="Req. Return">Req. Return</option>
          
        </select>
      </div>
      <div className="flex justify-end">
        <button className="mr-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded" onClick={handleStatusChange}>Update Status</button>
       
        <button className=" " onClick={closePopup}>
  <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
  </svg>
</button>
      </div>
    </div>
  </div>
)}

    </div>
  );
}
export default OrderHistory;
