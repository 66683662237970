import React from 'react';
import styled from 'styled-components';

// Styled components
const Title = styled.h2`
  font-size: 2.5rem;
  font-weight: bold;
  color: #9F4328; /* Rudraksha color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Bold shadow effect */
`;

const Text = styled.p`
  margin-top: 20px;
  font-size: 1.5rem;
  color: #666666; /* Adjust text color as needed */
`;

const QualityTitle = styled.h3`
  color: #4CAF50; /* Green color for quality products title */
`;

const ServiceTitle = styled.h3`
  color: #007BFF; /* Blue color for excellent service title */
`;

const ValueTitle = styled.h3`
  color: #FFC107; /* Yellow color for great value title */
`;

// Main component
const WhyChooseUs = () => {
  return (
    <div className="py-12">
      <div className="max-w-7xl mx-auto px-8 sm:px-6 lg:px-8">
        <div className="text-center">
          <Title>Why Choose Us?</Title>
          <Text>We strive to provide the best service and products to our customers. Here are some reasons to choose us:</Text>
        </div>
        <div className="mt-10">
          {/* Left side: Text */}
          <div className="text-container">
            <div className="flex flex-col items-center">
              <QualityTitle>Quality Products</QualityTitle>
              <Text>We offer high-quality products that meet your needs and expectations.</Text>
            </div>
            <div className="flex flex-col items-center mt-8 md:mt-0">
              <ServiceTitle>Excellent Service</ServiceTitle>
              <Text>Our dedicated team is committed to providing exceptional service to our customers.</Text>
            </div>
            <div className="flex flex-col items-center mt-8 md:mt-0">
              <ValueTitle>Great Value</ValueTitle>
              <Text>We offer competitive prices and great value for money on all our products.</Text>
            </div>
          </div>
          {/* Right side: Image */}
          {/* <div style={imageContainerStyle}></div> */}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
