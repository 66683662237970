import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const CertificateDetail = () => {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(
          `https://api.rudra.shivaksha.com/api/products/${productId}`
        );
        setProduct(response.data.product);
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchProduct();
  }, [productId]);

  return (
    <div>
      <h2>Product Details</h2>
      {product ? (
        <div>
          <p>Product ID: {product.id}</p>
          <p>Item Name: {product.item_name}</p>
          <p>Category: {product.category}</p>
          <p>Shape and Cut: {product.shape_and_cut}</p>
          <p>Weight: {product.weight}</p>
          <p>Color: {product.color}</p>
          {/* Add other details as needed */}
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default CertificateDetail;
