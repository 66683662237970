import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Wishlist.css'
const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchWishlistItems = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("user"));
        const userId = userData ? userData.id : null;
        if (!userId) {
          console.error('User ID not found in localStorage');
          setLoading(false);
          return;
        }
        const response = await axios.get(`https://api.rudra.shivaksha.com/api/getwishlist/${userId}`);
        setWishlistItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching wishlist items:', error);
        setLoading(false); // Update loading state even in case of error
      }
    };

    fetchWishlistItems(); 

    return () => {
      // Cleanup function (if any)
    };
  }, []);

  const addToWishlist = async (product_id) => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      const user_id = userData ? userData.id : null;
      if (!user_id) {
        console.error('User ID not found in localStorage');
        return;
      }
      const response = await axios.post(`http://localhost:3006/api/addwishlist`, { user_id, product_id });
      if (response.status === 201) {
        // Item added successfully
        setErrorMessage('');
        const updatedItems = [...wishlistItems, response.data];
        setWishlistItems(updatedItems);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Item is already added to the wishlist
        setErrorMessage('Item is already added to the wishlist');
      } else {
        console.error('Error adding item to wishlist:', error);
      }
    }
  };

  const removeFromWishlist = async (id) => {
    try {
      await axios.delete(`http://localhost:3006/api/removewishlist/${id}`);
      setWishlistItems(wishlistItems.filter(item => item.id !== id));
    } catch (error) {
      console.error('Error removing item from wishlist:', error);
    }
  };

  if (loading) {
    return <div className="text-center mt-4">Loading...</div>;
  }

  const titleStyle = {
    fontSize: '28px',
    color: '#761b0c', // Change color as needed
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Add text shadow
  };

  return (
    <div className="mx-auto p-4 h-screen mt-8">
      <h1 style={titleStyle} className="text-center mb-4">Wishlist</h1>
      {errorMessage && <div className="text-red-500">{errorMessage}</div>}
      {wishlistItems.length === 0 ? (
        <p>Your wishlist is empty.</p>
      ) : (
        <div className="overflow-x-auto">
          <table className="wishlist-table table-with-margin">
            <thead>
              <tr>
                <th className="px-4 py-2">Image</th>
                <th className="px-4 py-2">Product Name</th>
                <th className="px-4 py-2">Price</th>
                <th className="px-4 py-2">Date Added</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {wishlistItems.map(item => (
                <tr key={item.id}>
                  <td className="border px-4 py-2">
                    {/* <img src={`http://localhost:3006/${item.image_id}`} alt={`Product ${item.product_id}`} className="wishlist-image" /> */}
                    <img src={`https://api.rudra.shivaksha.com/${item.image_id}`}  alt={`Product ${item.product_id}`} className="wishlist-image" />
                  </td>
                  <td className="border px-4 py-2">{item.item_name}</td>
                  <td className="border px-4 py-2">{`$${item.unit_price_id}`}</td>
                  <td className="border px-4 py-2">{item.date_added}</td>
                  <td className="border px-4 py-2">
                    <button
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                      onClick={() => removeFromWishlist(item.id)}
                    >
                      Remove
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Wishlist;
