import React, { useEffect, useState } from 'react';
import axios from 'axios';

const MultiitemCarousel = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.rudra.shivaksha.com/api/get-customer-testimonials');
        setTestimonials(response.data);
      } catch (error) {
        console.error('Error fetching customer testimonials:', error);
      }
    };

    fetchData();

    // Autoplay functionality
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    }, 3000);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [testimonials.length]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1));
  };

  return (
    <div className="w-full">
      <div className="relative">
        <button className="absolute top-1/2 left-0 transform -translate-y-1/2 px-2 py-1 bg-gray-800 text-white rounded-l-md" onClick={handlePrev}>
          Prev
        </button>
        <button className="absolute top-1/2 right-0 transform -translate-y-1/2 px-2 py-1 bg-gray-800 text-white rounded-r-md" onClick={handleNext}>
          Next
        </button>
        <div className="overflow-hidden w-full">
          <div className="flex transition-transform duration-500 ease-in-out" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
            {testimonials.map((testimonial, index) => (
              <div key={index} className="flex-shrink-0 w-full sm:w-1/3">
                <div className="bg-gray-100 p-4 rounded-md shadow-md">
                  <img src={`https://api.rudra.shivaksha.com/${testimonial.image_url}`} alt={testimonial.customer_name} className="w-20 h-20 mx-auto rounded-full" />
                  <h3 className="text-lg font-semibold text-center mt-4">{testimonial.customer_name}</h3>
                  <p className="text-sm text-gray-600 text-center mt-2">{testimonial.testimonial}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiitemCarousel;
