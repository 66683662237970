import React, { useState, useEffect } from 'react';

function UserWallet() {
  const [walletAmount, setWalletAmount] = useState(1000);
  const [requestedAmount, setRequestedAmount] = useState(0);
  const [walletStatus, setWalletStatus] = useState('pending');
  const [filteredWallets, setFilteredWallets] = useState([]);
  const [filter, setFilter] = useState('All');
  const [activeButton, setActiveButton] = useState(null);

  useEffect(() => {
    filterWallets();
  }, [walletStatus, filter]);

  const filterWallets = () => {
    const wallets = [
      { id: 1, amount: 500, status: 'pending' },
      { id: 2, amount: 750, status: 'completed' },
    ];

    let filtered = wallets;
    if (filter !== 'All') {
      filtered = wallets.filter(wallet => wallet.status === filter);
    }

    setFilteredWallets(filtered);
  };

  const handleRedeem = () => {
    if (walletAmount >= 1000) {
      setRequestedAmount(walletAmount);
      setWalletAmount(0);
      setWalletStatus('pending');
    } else {
      alert('Wallet amount must be at least Rs. 1000 to redeem.');
    }
  };

  const handleClick = (filter) => {
    setFilter(filter);
    setActiveButton(filter);
  };

  return (
    <div className="p-4 overflow-x-auto">
      <h1 className="text-2xl font-bold mb-4">User Wallet</h1>
      <p className="mb-4">Wallet Amount: Rs. {walletAmount}</p>
      <button 
        onClick={handleRedeem} 
        disabled={walletAmount < 1000}
        className=" text-white px-4 py-2 rounded-md mb-4"style={{ backgroundColor: '#761b0c', color: '#fff' }}
      >
        Redeem
      </button>

      <div className="mb-4">
        <h2 className="text-lg font-semibold mb-2">Filtered Wallets</h2>
        <div className="flex space-x-4">
          <button 
            onClick={() => handleClick('All')} 
            className={`px-4 py-2 rounded-md ${activeButton === 'All' ? 'border border-black bg-white text-black' : ' text-white'} hover:bg-gray-900 hover:text-white`}
            style={{ backgroundColor: '#761b0c', color: '#fff' }} >
            All
          </button>
          <button 
            onClick={() => handleClick('pending')} 
            className={`px-4 py-2 rounded-md ${activeButton === 'pending' ? 'border border-black bg-white text-black' : ' text-white'} hover:bg-gray-900 hover:text-white`}
            style={{ backgroundColor: '#761b0c', color: '#fff' }}>
            Requested
          </button>
          <button 
            onClick={() => handleClick('completed')} 
            className={`px-4 py-2 rounded-md ${activeButton === 'completed' ? 'border border-black bg-white text-black' : ' text-white'} hover:bg-gray-900 hover:text-white`}
            style={{ backgroundColor: '#761b0c', color: '#fff' }}>
            Completed
          </button>
        </div>
      </div>

      <div className="mb-4">
        <table className="w-full">
          <thead>
            <tr>
              <th className="px-4 py-2"style={{ backgroundColor: '#761b0c', color: '#fff' }}>Wallet ID</th>
              <th className="px-4 py-2"style={{ backgroundColor: '#761b0c', color: '#fff' }}>Amount</th>
              <th className="px-4 py-2"style={{ backgroundColor: '#761b0c', color: '#fff' }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {filteredWallets.map(wallet => (
              <tr key={wallet.id} className="border-b border-gray-200">
                <td className="px-4 py-2">{wallet.id}</td>
                <td className="px-4 py-2">Rs. {wallet.amount}</td>
                <td className="px-4 py-2">{wallet.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {walletStatus === 'pending' && (
        <div>
          <p>Requested Amount: Rs. {requestedAmount}</p>
          <p>Status: Requested</p>
        </div>
      )}
    </div>
  );
}

export default UserWallet;
