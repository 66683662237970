import React from 'react';
import styled, { keyframes } from 'styled-components';

// Define keyframes for animation
const marquee = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(-100%); }
`;

// Styled NotificationBar component
const StyledNotificationBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #dc2626; /* Red */
  color: #f3f4f6; /* White */
  padding: 0.5rem;
  text-align: center;
  font-weight: 600;
  overflow: hidden;
  z-index: 1000; /* Adjust z-index as needed */
`;

// Styled text span for animation
const MarqueeText = styled.span`
  display: inline-block;
  animation: ${marquee} 40s linear infinite;
  font-family: Arial, sans-serif; /* Adjust font as needed */
  white-space: nowrap; /* Ensure text stays on one line */
`;

const NotificationBar = () => {
  return (
    <StyledNotificationBar>
      <MarqueeText>
        Get <span style={{ color: '#fbbf24' }}>5% OFF</span> on all purchases below ₹5,000 and <span style={{ color: '#fbbf24' }}>7% OFF</span> on all purchases above ₹5,000! Use code <span style={{ color: '#fbbf24' }}>SHIVAKSHA</span>. Limited time offer.  पाइए  <span style={{ color: '#fbbf24' }}>५ % छूट</span> रुपये ५०००  के नीचे की सभी खरीदारी पर और <span style={{ color: '#fbbf24' }}>७ % छूट</span> रुपये ५०००  के ऊपर की सभी खरीदारी पर! कोड <span style={{ color: '#fbbf24' }}>SHIVAKSHA</span> का उपयोग करें. सीमित समय का प्रस्ताव.
      </MarqueeText>
    </StyledNotificationBar>
  );
};

export default NotificationBar;
