import React, { useState, useEffect } from "react";

const SubscribePopup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [redeemCode, setRedeemCode] = useState("");
  const [showRedeemCode, setShowRedeemCode] = useState(false);
  const [subscribe, setSubscribe] = useState(false);
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [user, setUser] = useState(null); // State to hold user data

  // Fetch user data from local storage when component mounts
  useEffect(() => {
    const userData = localStorage.getItem('user');
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  // Check if the popup has been shown to the user
  useEffect(() => {
    const popupShown = localStorage.getItem('popupShown');
    if (!popupShown) {
      setShowPopup(true);
      localStorage.setItem('popupShown', true);
    }
  }, []);

  const handleSubscribePopup = () => {
    if (!user) {
      alert("User must be logged in to subscribe.");
    } else {
      setShowPopup(false);
      setSubscribe(true);
    }
  };

  const closeSubscribe = () => {
    setShowPopup(false);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (!user) {
      alert("User must be logged in to subscribe.");
      return;
    }
    try {
      const userId = user.id;
      const response = await fetch('https://api.rudra.shivaksha.com/api/generateVoucher', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: userId,
          email: userEmail
        })
      });
      const data = await response.json();
      if (response.ok) {
        const code = data.voucherCode;
        setRedeemCode(code);
        setShowRedeemCode(true);
        setUserEmail("");
        setUserName("");
        setSubscriptionSuccess(true);
      } else {
        console.error('Failed to generate voucher:', data.error);
      }
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  };

  const handleCancelSubscribe = () => {
    setSubscribe(false);
  };

  const handleCloseRedeemCode = () => {
    setShowRedeemCode(false);
  };

  return (
    <>
      {/* main subscribe popup that pops for unlogged users */}
      {showPopup && !user && (
        <div>
          <div className=" bg-slate-50 w-[95%] md:w-[80%] lg:w-[45%] shadow-md shadow-slate-500 rounded-md py-3 px-6 fixed z-[9999] top-14 ml-3 lg:ml-0 md:right-24">
            <div className=" flex justify-between text-lg font-semibold">
              <div>Subscribe and Win!</div>
              <div onClick={closeSubscribe} className=" cursor-pointer">
                &times;
              </div>
            </div>
            <div>
              Subscribe to receive a lucky gift hamper voucher code at checkout.
            </div>
            <div className=" flex justify-end">
              <button
                className=" bg-inherit border-[1px] border-slate-500 rounded-md px-5 py-2 mt-4 active:ring-2 active:ring-slate-400"
                onClick={handleSubscribePopup}
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      )}

      {/* this section shows the subscribe page from where user can input their name and email to subscribe and get the redeem code  */}
      {subscribe && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50">
          <div className="bg-slate-50 shadow-md shadow-slate-400 rounded-md px-4 py-2 w-[70%] md:w-[50%] lg:w-[25%]">
            <p className="text-2xl font-semibold">
              Subscribe for a Lucky Gift Hamper Voucher Code
            </p>
            <form action="" className="grid grid-cols-1 gap-4">
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  placeholder="Enter your name"
                  required
                  maxLength={25}
                  className="border-slate-500 border-[1px] rounded-md px-4 py-2"
                  id="name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>
              <div className="grid grid-cols-1 gap-1">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  required
                  className="border-slate-500 border-[1px] rounded-md px-4 py-2"
                  id="email"
                  value={userEmail}
                  onChange={(e) => setUserEmail(e.target.value)}
                />
              </div>
              <div className="flex justify-between">
                <button
                  className="bg-black text-white text-sm w-fit px-5 rounded-md py-2 mb-2 active:ring-2 active:ring-slate-500"
                  onClick={handleSubscribe}
                >
                  Subscribe
                </button>
                <div
                  className="bg-slate-300 cursor-pointer text-slate-800 text-sm w-fit px-5 rounded-md py-2 mb-2 active:ring-2 active:ring-slate-500"
                  onClick={handleCancelSubscribe}
                >
                  Cancel
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* this section is for generating the redeem code and showing it to the user */}
      {showRedeemCode && (
        <div className="bg-slate-50 top-5  left-[30%] z-[9999] fixed  shadow-md shadow-slate-400 rounded-md px-4 py-2 w-[70%] md:w-[50%] lg:w-[25%]">
          <p className="text-lg font-semibold">Subscribed Successfully!!!</p>
          <p>Please Check your Mailbox for Redeem Code. Thank you</p>
          <div className="flex justify-end">
            <button
              className="bg-black text-white px-4 py-1 rounded-md"
              onClick={handleCloseRedeemCode}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default SubscribePopup;
