import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent } from '@material-ui/core';
import { GiShop } from 'react-icons/gi';
import styled from 'styled-components';
// import bannerImage from '../../photos/download (7).jpeg'; 

const MainContainer = styled.div`
  padding: 0 0px;
`;

const BannerSection = styled.section`
  margin-bottom: 40px;
`;

const BannerCard = styled(Card)`
  width: 100%;
  height: 87vh;
  overflow: hidden;
  position: relative;
`;

const BannerImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: blur(3px); /* Add blur effect */
`;

const ContentContainer = styled(CardContent)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem; /* Adjust font size */
  font-weight: bold;
  color: #fff; /* Change text color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add text shadow */
`;

const StyledButton = styled(Button)`
  && {
    background-color: transparent; /* Transparent background */
    color: #fff; /* Text color */
    border: 2px solid #fff; /* Add outline */
    &:hover {
      background-color: transparent; /* Transparent background on hover */
    }
  }
`;

function Banner() {
  return (
    <MainContainer>
      <main>
        <BannerSection>
          <BannerCard>
            {/* <BannerImage
              alt="Hero Banner"
              src={bannerImage} // Use the imported image
            /> */}
            <ContentContainer>
              <Title>Welcome to Shivaksha Spiritual Sites</Title> {/* Add welcome text */}
              <Link to="/ShoppingPage">
                <StyledButton
                  variant="outlined" // Use outlined button
                  startIcon={<GiShop />}
                >
                  Shop Now
                </StyledButton>
              </Link>
            </ContentContainer>
          </BannerCard>
        </BannerSection>
      </main>
    </MainContainer>
  );
}

export default Banner;
