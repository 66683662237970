import React, { useRef, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 28px;
  color: #761b0c;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

const DividerContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Divider = styled.img`
  width: 100px;
  margin-top: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  animation: ${fadeInUp} 1s ease-in-out;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: ${({ isVisible }) => (isVisible ? 'translateY(0)' : 'translateY(20px)')};
`;

const Content = styled.div`
  flex: 1;
  text-align: left;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
`;

const RudrakshaTextStyle = styled.span`
  color: #761b0c;
  font-style: italic;
  font-weight: bold;
`;

const TLRUdraksha = () => {
  const contentRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const top = contentRef.current.getBoundingClientRect().top;
      const isVisible = top < window.innerHeight;
      setIsVisible(isVisible);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Container>
      <TitleContainer>
        <Title>The Legend of Rudraksha</Title>
        <DividerContainer>
          <Divider src="https://static.ishaoutreach.org/static/assets/rudraksha-diksha/img/diskha-divider.svg" alt="Divider" />
        </DividerContainer>
      </TitleContainer>
      <ContentContainer isVisible={isVisible} ref={contentRef}>
        <Content>
          <p>
            Rudra means <RudrakshaTextStyle>Shiva</RudrakshaTextStyle>, aksha means teardrops. Rudraksha are the teardrops of Shiva. The legend goes that once Shiva sat for meditation for a long period of time. His ecstasy was such that it made him absolutely still, unmoving. He seemingly did not even breathe, and everyone thought he was dead. There was only one sign of life - the tears of ecstasy that dribbled from his eyes. These teardrops fell upon the earth and became Rudraksha, “<RudrakshaTextStyle>Shiva’s tears</RudrakshaTextStyle>.”
          </p>
        </Content>
      </ContentContainer>
    </Container>
  );
};

export default TLRUdraksha;
