import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faMicrophone, faVideoSlash, faMicrophoneSlash } from '@fortawesome/free-solid-svg-icons';

const VideoCall = () => {
  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [users, setUsers] = useState([
    { id: 1, name: 'John Doe', online: true },
    { id: 2, name: 'Jane Smith', online: false },
    { id: 3, name: 'Bob Johnson', online: true }
  ]);
  const [showPopup, setShowPopup] = useState(false);
  const [callStatus, setCallStatus] = useState('');
  const [videoEnabled, setVideoEnabled] = useState(true);
  const [audioMuted, setAudioMuted] = useState(false);
  const localVideoRef = useRef();
  const remoteVideoRef = useRef();

  useEffect(() => {
    // Function to start the video call
    const startVideoCall = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        setLocalStream(stream);
        localVideoRef.current.srcObject = stream;
      } catch (error) {
        console.error('Error accessing media devices:', error);
      }
    };

    startVideoCall();

    // Cleanup function to stop the video call when component unmounts
    return () => {
      if (localStream) {
        localStream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  // Function to handle incoming remote stream
  const handleRemoteStream = (event) => {
    setRemoteStream(event.streams[0]);
    remoteVideoRef.current.srcObject = event.streams[0];
  };

  // Function to initiate a video call with a user
  const initiateCall = async (userId) => {
    const user = users.find(u => u.id === userId);
    if (!user.online) {
      setCallStatus('Connecting...');
      setShowPopup(true);
      setTimeout(() => {
        setShowPopup(false);
      }, 3000); // Close popup after 3 seconds
    } else {
      setCallStatus('Ringing...');
      setShowPopup(true);
      // Additional logic to establish connection and start ringing
    }
  };

  const handleHideVideo = () => {
    setVideoEnabled(!videoEnabled);
    localStream.getVideoTracks().forEach(track => {
      track.enabled = !videoEnabled;
    });
  };

  const handleMuteAudio = () => {
    setAudioMuted(!audioMuted);
    localStream.getAudioTracks().forEach(track => {
      track.enabled = !audioMuted;
    });
  };

  return (
    <div className="container mx-auto py-8">
      <div className="grid grid-cols-2 gap-8">
        <div>
          <h2 className="text-lg font-semibold mb-4">Your Video</h2>
          <div className="relative">
            <video className="w-full rounded-lg shadow-md" ref={localVideoRef} autoPlay playsInline muted={!localStream}></video>
            <span className="absolute top-2 right-2 text-red-500">
              {videoEnabled ? <FontAwesomeIcon icon={faVideo} /> : <FontAwesomeIcon icon={faVideoSlash} />}
            </span>
            <span className="absolute top-8 right-2 text-red-500">
              {audioMuted ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : <FontAwesomeIcon icon={faMicrophone} />}
            </span>
          </div>
          <div className="mt-2">
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2" onClick={handleHideVideo}>{videoEnabled ? 'Hide Video' : 'Show Video'}</button>
            <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={handleMuteAudio}>{audioMuted ? 'Unmute Audio' : 'Mute Audio'}</button>
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold mb-4">Remote Video</h2>
          <div className="relative">
            <video className="w-full rounded-lg shadow-md" ref={remoteVideoRef} autoPlay playsInline muted={!remoteStream}></video>
            <span className="absolute top-2 right-2 text-red-500">
              {audioMuted ? <FontAwesomeIcon icon={faMicrophoneSlash} /> : null}
            </span>
            <span className="absolute top-8 right-2 text-red-500">
              {videoEnabled ? null : <FontAwesomeIcon icon={faVideoSlash} />}
            </span>
          </div>
        </div>
      </div>
      {showPopup && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
    <div className="bg-white rounded-lg shadow-lg overflow-hidden relative" style={{ maxWidth: '520px', maxHeight: '840px', height: '80vh', width:'1200px' }}>
      <div className="flex justify-center items-center bg-gray-200 p-4">
        <img src="https://lh3.googleusercontent.com/a/ACg8ocLFoLYLpKiowOUY3UrTcsG58RiHUGR3u2Kxkyq6FeyLH43iqWyP8g=s96-c-rg-br100" alt="User Avatar" className="w-16 h-16 rounded-full mr-4" />
       
      </div>
      <div>
      <p className="text-lg font-semibold">{callStatus}</p>
      <video className="w-full rounded-lg shadow-md" ref={localVideoRef} autoPlay playsInline muted={!localStream}></video>
</div>
      <div className="flex justify-center items-center h-full">
        <button className="absolute bottom-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600" onClick={() => setShowPopup(false)}>End Call</button>
      </div>
    </div>
  </div>
)}

      <div className="mt-8">
        <h2 className="text-lg font-semibold mb-4">Users</h2>
        <ul>
          {users.map(user => (
            <li key={user.id} className="flex items-center justify-between py-2 border-b border-gray-200">
              <span>{user.name}</span>
              <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => initiateCall(user.id)}>Call</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default VideoCall;
