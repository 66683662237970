import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

const ContentContainer = styled.div`
  max-width: 800px; /* Adjust the maximum width as needed */
  width: 100%;
`;

const Footer = styled.div`
  margin-top: auto;
`;

const BlogTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: start;
`;

const BlogImage = styled.img`
  width: 100%;
  max-width: 600px;
  height: auto;
  object-fit: cover; /* Ensure the image covers the container */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

const SectionContent = styled.div`
  margin-bottom: 2rem;
  overflow: auto; /* Enable scrolling if content exceeds container height */
`;


const BlogDetails = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`http://localhost:3006/api/getallblogpostsById/${postId}`)
      .then(response => response.json())
      .then(data => {
        const formattedContentEN = data[0].Content_EN.split('. ').map((point, index) => {
          const [beforeColon, afterColon] = point.split(': ');
          const [title, content] = afterColon ? [beforeColon, afterColon] : ['', beforeColon];
          return (
            <div key={index}>
              {title && <strong>{title}</strong>}
              {title && content && ': '}
              {content}
            </div>
          );
        });
        const formattedContentHI = data[0].Content_HI.split('. ').map((point, index) => {
          const [beforeColon, afterColon] = point.split(': ');
          const [title, content] = afterColon ? [beforeColon, afterColon] : ['', beforeColon];
          return (
            <div key={index}>
              {title && <strong>{title}</strong>}
              {title && content && ': '}
              {content}
            </div>
          );
        });
        setPost({ ...data[0], formattedContentEN, formattedContentHI });
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog post:', error);
        setLoading(false);
      });
  }, [postId]);
  
  if (loading) {
    return <p>Loading...</p>;
  }

  if (!post) {
    return (
      <Container>
        <p>Blog post not found.</p>
      </Container>
    );
  }

  return (
    <Container className="container py-4">
      <ContentContainer>
        {/* Title Section */}
        <div className="mb-4">
          <BlogTitle>{post.Title}</BlogTitle>
        </div>
        
        {/* Image Section */}
        <div className="mb-4">
          <BlogImage src={`https://api.rudra.shivaksha.com/${post.ImagePath}`} alt="Blog Post" />
        </div>
        
        {/* Content_EN Section */}
        <SectionTitle>Content (English)</SectionTitle>
        <SectionContent>
          {post.formattedContentEN}
        </SectionContent>

        {/* Content_HI Section */}
        <SectionTitle>Content (Hindi)</SectionTitle>
        <SectionContent>
          {post.formattedContentHI}
        </SectionContent>
      </ContentContainer>

      {/* Author Section (Footer) */}
      <Footer>
        <p className="text-muted">Author: {post.Author}</p>
      </Footer>
    </Container>
  );
};

export default BlogDetails;
