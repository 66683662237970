import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';

const StyledFooter = styled.footer`
  background-color: #212529; /* Dark background color */
  color: #ffffff; /* Text color */
  padding: 20px; /* Padding for the footer */

`;

const FooterContainer = styled.div`
  max-width: 1200px; /* Maximum width of the footer */
  margin: 0 auto; /* Center the footer horizontally */
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: space-between; /* Space items evenly */
`;

const FooterColumn = styled.div`
  flex: 1; /* Occupy equal space in the container */
  margin-bottom: 20px; /* Margin between columns */
`;

const FooterHeading = styled.h5`
  font-size: 18px; /* Footer heading font size */
  font-weight: bold; /* Footer heading font weight */
  margin-bottom: 10px; /* Margin bottom for the heading */
`;

const FooterParagraph = styled.p`
  font-size: 14px; /* Font size for paragraph */
`;

const SocialMediaLink = styled.a`
  color: #ffffff; /* Social media icon color */
  margin-right: 10px; /* Margin between social media icons */
`;

const FooterInput = styled.input`
  background-color: #343a40; /* Input background color */
  color: #ffffff; /* Input text color */
  padding: 10px; /* Padding for the input */
  border: none; /* No border */
  border-radius: 5px; /* Border radius */
  margin-bottom: 10px; /* Margin bottom for the input */
`;

const SubscribeButton = styled.input`
  background-color: #ffc107; /* Button background color */
  color: #212529; /* Button text color */
  padding: 10px 20px; /* Padding for the button */
  border: none; /* No border */
  border-radius: 5px; /* Border radius */
  cursor: pointer; /* Cursor style */
`;

const Footer = () => {
  return (
    <StyledFooter>
      <FooterContainer>
        <FooterColumn>
          <FooterHeading>SHIVAKSHA</FooterHeading>
          <FooterParagraph>We strive to provide the best service and products to our customers.</FooterParagraph>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading>Quick Links</FooterHeading>
          <ul>
            <li><a href="#" className="text-white">Get Started</a></li>
            <li><a href="#" className="text-white">Top Leaders</a></li>
          </ul>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading>Follow Us</FooterHeading>
          <div>
            <SocialMediaLink href="#"><FontAwesomeIcon icon={faFacebook} /></SocialMediaLink>
            <SocialMediaLink href="#"><FontAwesomeIcon icon={faTwitter} /></SocialMediaLink>
            <SocialMediaLink href="#"><FontAwesomeIcon icon={faYoutube} /></SocialMediaLink>
          </div>
        </FooterColumn>
        <FooterColumn>
          <FooterHeading>Contact Us</FooterHeading>
          <FooterParagraph>Lorem ipsum dolor sit, amet consectetur adipisicing elit.</FooterParagraph>
          <div>
            <FooterInput type="text" name="email" placeholder="Email" />
            <SubscribeButton type="submit" value="Subscribe" />
          </div>
        </FooterColumn>
      </FooterContainer>
      <div className="bg-gray-800 py-2">
        <div className="text-center">
          <p className="text-gray-500 text-sm">Copyright Shivaksha © 2024. All rights reserved.</p>
        </div>
      </div>
    </StyledFooter>
  );
};

export default Footer;
