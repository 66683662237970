import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoMdClose, IoMdAdd } from "react-icons/io";
import { AiOutlineDelete, AiOutlineMinus } from "react-icons/ai";
import { Link } from "react-router-dom";
import loader from "../../photos/Ripple-1s-200px.gif";
import "./Cart.css";

const Cart = ({ onClose }) => {
  const [items, setItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cartOpen, setCartOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchCartItems = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (!userData || !userData.id) {
        console.error(
          "User ID not found. Please make sure the user is authenticated."
        );
        setIsLoggedIn(false);
        setLoading(false);
        return;
      }
      setIsLoggedIn(true);
      const userId = userData.id;
      const response = await axios.get(
        `https://api.rudra.shivaksha.com/api/getAllCartItems/${userId}`
      );
      setItems(response.data);
      const total = response.data.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
      setTotalPrice(total);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      fetchCartItems();
    }, 2000);

    return () => clearInterval(timer);
  }, []);

  const handleQuantity = async (id, change) => {
    try {
      const existingItemIndex = items.findIndex((item) => item.cart_id === id);
      if (existingItemIndex !== -1) {
        const updatedItems = [...items];
        const existingItem = updatedItems[existingItemIndex];
        const newQuantity = Math.max(existingItem.quantity + change, 1);
        existingItem.quantity = newQuantity;
        setItems(updatedItems);
        await axios.put(
          `https://api.rudra.shivaksha.com/api/updateCartItemQuantity/${id}`,
          { quantity: newQuantity }
        );
      } else {
        const response = await axios.get(
          `https://api.rudra.shivaksha.com/api/getCartItem/${id}`
        );
        const newItem = response.data;
        newItem.quantity = 1;
        setItems([...items, newItem]);
      }

      const total = items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
      setTotalPrice(total);
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.delete(
        `https://api.rudra.shivaksha.com/api/deleteCartItem/${id}`
      );
      setItems(items.filter((item) => item.cart_id !== id));

      const total = items.reduce(
        (total, item) => total + item.price * item.quantity,
        0
      );
      setTotalPrice(total);
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  return (
    <div
      className={`fixed top-0 left-0 h-full w-52rem bg-white z-50 transform transition-transform duration-300 ${
        cartOpen ? "translate-x-0" : "-translate-x-42rem"
      } w-full lg:w-[30rem] `}
      // style={{ minWidth: "430px" }}
    >
      <div className="bg-red-500 text-white py-2 px-4 flex justify-between items-center">
        <div className="text-lg font-bold">Your Cart</div>
        <button onClick={() => onClose()}>
          <IoMdClose className="text-lg" />
        </button>
      </div>
      <div className="overflow-y-auto h-full px-4 py-2">
        {loading ? (
          <div className="flex items-center justify-center h-full">
            <div
              className="text-gray-600 font-semibold text-center py-4"
              style={{ minWidth: "350px" }}
            >
              <div className="flex items-center justify-center">
                <img src={loader} alt="Loading..." />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex items-center justify-between mb-4 border-b pb-2">
              <div className="font-bold text-sm" style={{ width: "15%" }}>
                S.N
              </div>
              <div className="font-bold text-sm" style={{ width: "50%" }}>
                Products
              </div>
              <div className="font-bold text-sm" style={{ width: "20%" }}>
                Quantity
              </div>
              <div className="font-bold text-sm" style={{ width: "15%" }}>
                Prices
              </div>
              <div className="font-bold text-sm" style={{ width: "10%" }}>
                Action
              </div>
            </div>
            {items.map((item, index) => (
              <div
                key={item.cart_id}
                className="flex items-center justify-between mb-4 border-b pb-2 hover:bg-gray-100 transition-colors duration-300"
              >
                <div className="font-medium text-sm" style={{ width: "15%" }}>
                  {index + 1}
                </div>
                <div
                  className="flex items-center space-x-4"
                  style={{ width: "80%" }}
                >
                  <img
                    src={`https://api.rudra.shivaksha.com/${item.image_path}`}
                    alt=""
                    className="w-12 h-12 rounded-full"
                  />
                  <div>
                    <div className="text-sm font-medium">{item.item_name}</div>
                    <div className="text-xs text-gray-500">{item.category}</div>
                  </div>
                </div>
                <div
                  className="flex items-center space-x-2"
                  style={{ width: "40%" }}
                >
                  <button
                    onClick={() => handleQuantity(item.cart_id, -1)}
                    className="bg-gray-200 text-gray-700 rounded-full px-2 hover:bg-gray-300"
                  >
                    <AiOutlineMinus />
                  </button>
                  <span className="text-sm">{item.quantity}</span>
                  <button
                    onClick={() => handleQuantity(item.cart_id, 1)}
                    className="bg-gray-200 text-gray-700 rounded-full px-2 hover:bg-gray-300"
                  >
                    <IoMdAdd />
                  </button>
                </div>
                <div className="font-bold text-lg" style={{ width: "40%" }}>
                  ${(item.price * item.quantity).toFixed(2)}
                </div>
                <div style={{ width: "10%" }}>
                  <button
                    onClick={() => handleDeleteItem(item.cart_id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <AiOutlineDelete />
                  </button>
                </div>
              </div>
            ))}

            {!isLoggedIn && (
              <div className="text-red-500 font-semibold mb-4 mt-12 p-10 rounded-md text-center">
                <p className="text-lg mx-auto" style={{ maxWidth: "800px" }}>
                  Please log in to view your cart.
                </p>
              </div>
            )}
          </>
        )}
      </div>
      <div className="bg-blue-500 text-white py-4 px-4 flex justify-between items-center absolute bottom-0 left-0 w-full">
        <div className="font-bold text-xl">
          Total Price: ${totalPrice.toFixed(2)}
        </div>
        <div>
          <Link to="/CartPage">
            <button
              onClick={() => onClose()}
              className="bg-blue-400 hover:bg-black-400 text-white rounded-md py-1 px-2 focus:outline-none focus:ring-2 focus:ring-black-200 transition-colors duration-300"
            >
              View Cart
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
