import React, { useState } from 'react';
import UserWallet from './UserWallet';
import OrderReturnHistory from './OrderReturnHistory';
import OrderHistory from './OrderHistory';
import Footer from '../Footer/Footer';

function UserProfile() {
  // State variables for user details and active filters
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');

  // Function to handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted user details:', { username, email });
  };

  return (
    <>
    <div className="mx-auto px-4 mt-10">
      <h1 className="text-2xl font-bold mb-4">User Account</h1>
      <form className="mb-4">
  {/* Profile Picture */}
 

  {/* Email */}
  <div className="flex flex-col mb-4">
    <label htmlFor="email" className="mb-1">Email:</label>
    <input 
      type="email" 
      id="email" 
    
      placeholder='Data will fetched From KYC '
      required 
      className="border border-gray-300 rounded-md p-2"
    />
  </div>

  {/* Username */}
  <div className="flex flex-col mb-4">
    <label htmlFor="username" className="mb-1">Username:</label>
    <input 
      type="text" 
      id="username" 
     placeholder='Data will fetched From KYC '
    
      required 
      className="border border-gray-300 rounded-md p-2"
    />
  </div>

  {/* Name */}
  <div className="flex flex-col mb-4">
    <label htmlFor="name" className="mb-1">Name:</label>
    <input 
      type="text" 
      id="name" 
    
      placeholder='Data will fetched From KYC '
      required 
      className="border border-gray-300 rounded-md p-2"
    />
  </div>

  
</form>


      {/* Order History */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Order History</h2>
        <OrderHistory />
      </section>

      {/* Order Return History */}
      <section className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Return History</h2>
        <OrderReturnHistory />
      </section>

      {/* User Wallet */}
      <section>
        <h2 className="text-2xl font-bold mb-4">Wallet</h2>
        <UserWallet />
      </section>
    
    </div>
  
      </>
  );
}

export default UserProfile;
