import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const ShowBlog = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://api.rudra.shivaksha.com/api/getallblogposts')
      .then(response => response.json())
      .then(data => {
        setBlogPosts(data);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      });
  }, []);

  return (
    <div className="mx-auto mt-8">
      {loading ? (
        <p className="text-center">Loading...</p>
      ) : blogPosts.length > 0 ? (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mx-2 mt-16 ">
          {blogPosts.map(post => (
            <Link to={`/blog/${post.PostID}`} key={post.PostID}> {/* Link to the blog post details page */}
              <div className="bg-white shadow-md rounded-md overflow-hidden hover:scale-105 transition duration-300 ease-in-out cursor-pointer">
                <img src={`https://api.rudra.shivaksha.com/${post.ImagePath}`} alt="Blog Post" className="w-full h-64 object-cover hover:scale-105 transition duration-300 ease-in-out" />
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">{post.Title}</h2>
                  <p className="text-gray-600">Posted by: {post.Author}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <p className="text-center">No blog posts found.</p>
      )}
    </div>
  );
};

export default ShowBlog;
