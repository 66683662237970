import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { faL } from "@fortawesome/free-solid-svg-icons";
const LogIn = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.rudra.shivaksha.com/api/login",
        formData
      );
      if (response.status === 200) {
        localStorage.setItem("user", JSON.stringify(response.data.user));
        navigate("/");
      } else {
        console.error("Login failed:", response.data.error);
      }
    } catch (error) {
      console.error("Login failed:", error.message);
    }
  };

  return (
    <div className="container mx-auto px-4 md:px-0">
      <div className="rounded-md shadow-md shadow-slate-400 bg-slate-50 mx-auto my-5 md:w-[35%]">
        <div className="text-2xl font-semibold text-center mt-2">Log In</div>

        <form
          className="grid grid-cols-1 gap-4 px-5 py-3"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="border-[1px] border-slate-500 rounded-md p-2"
            />
          </div>
          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="password">Password</label>
            <div className="relative flex">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                name="password"
                value={formData.password}
                onInput={() => {
                  passwordVisibility === false
                    ? setPasswordVisibility(true)
                    : setConfirmPasswordVisibility(false);
                }}
                onChange={handleChange}
                className="border-[1px] border-slate-500 rounded-md p-2 flex-grow"
              />

              {passwordVisibility && (
                <button
                  type="button"
                  className="text-gray-500 focus:outline-none absolute right-3 top-2"
                  onClick={handleTogglePasswordVisibility}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              )}
            </div>
          </div>
          {/* <div className="grid grid-cols-1 gap-1">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="relative flex">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                name="confirmPassword"
                value={formData.confirmPassword}
                onInput={() => {
                  confirmPasswordVisibility === false
                    ? setConfirmPasswordVisibility(true)
                    : setConfirmPasswordVisibility(false);
                }}
                onChange={handleChange}
                className="border-[1px] border-slate-500 rounded-md p-2 flex-grow"
              />

              {confirmPasswordVisibility && (
                <button
                  type="button"
                  className="text-gray-500 focus:outline-none absolute right-3 top-2"
                  onClick={handleToggleConfirmPasswordVisibility}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </button>
              )}
            </div>
          </div> */}

          <div className="grid grid-cols-1 gap-4 md:gap-2 md:grid-cols-2">
            <button
              type="submit"
              className="bg-black text-white text-sm px-7 py-2 rounded-md w-fit active:ring-2 active:ring-slate-500"
            >
              Login
            </button>
            <div className="underline text-slate-500 text-right">
              Forget password?
            </div>
          </div>

          <div className="text-center">
            Don't have an account?{" "}
            <span className="underline text-slate-600">
              <Link to="/sign-up">Sign up</Link>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LogIn;
