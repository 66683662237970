import React, { useState, useEffect } from "react";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineMinus } from "react-icons/ai";
import axios from "axios";
import "./CartPage.css";
import loader from "../../photos/Ripple-1s-200px.gif";

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState([]);
  const totalSteps = 3; // Total number of steps
  const [currentStep, setCurrentStep] = useState(1);

  const [redeemDiscount, setRedeemDiscount] = useState(0);
  const [walletAmount, setWalletAmount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [error, setError] = useState("");
  const [redeemCode, setRedeemCode] = useState("");
  const [discount, setDiscount] = useState(0);
  // const [grandTotal, setGrandTotal] = useState();
  // wallets usestates
  const [walletPopup, setWalletPopup] = useState(false);
  const [useWallet, setUseWallet] = useState(false);

  const handleNext = async () => {
    try {
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error("Error sending user address data:", error);
      // Handle error
      alert("Error sending user address data. Please try again later.");
    }
  };

  const handlePrev = () => {
    setCurrentStep(currentStep - 1);
  };

  // Function to calculate total amount including redeem discount
  const calculateTotalAmount = (cartItems, redeemDiscount) => {
    const subtotal = cartItems.reduce(
      (total, item) => total + item.price * item.quantity,
      0
    );
    return subtotal;
  };

  // Calculate the total amount including redeem discount
  const totalAmount = calculateTotalAmount(cartItems, redeemDiscount);

  // Function to calculate shipping based on the total amount
  const calculateShipping = (totalAmount) => {
    const subtotal = totalAmount - redeemDiscount;
    return subtotal < 5500 ? 50 : 0;
  };

  // Function to calculate discount based on total amount
  // const calculateDiscount = () => {
  //   if (totalAmount <= 5000) {
  //     setDiscount(totalAmount * 0.05);
  //   }
  //   // } else if (totalAmount > 5000 && totalAmount <= 10000) {
  //   //   setDiscount(totalAmount * 0.1);
  //   // }
  //   else {
  //     setDiscount(totalAmount * 0.15);
  //   }
  // };

  // Function to handle redeeming voucher
  const handleRedeemCode = async () => {
    try {
      if (!redeemCode) {
        setError("Please enter a redeem code.");
        return;
      }

      if (redeemCode.toUpperCase() === "SHIVAKSHA") {
        let newRedeemDiscount = 0;
        if (totalAmount < 2000) {
          newRedeemDiscount = totalAmount * 0.02;
        } else {
          newRedeemDiscount = totalAmount * 0.03;
        }
        setRedeemDiscount(newRedeemDiscount);
        setError("");
        // Save redeem code in localStorage
        localStorage.setItem("redeemCode", redeemCode);
      } else {
        setError("Invalid redeem code.");
        setRedeemDiscount(0);
      }
    } catch (error) {
      console.error("Error redeeming voucher:", error);
      setError("Error redeeming voucher. Please try again later.");
      setRedeemDiscount(0);
    }
  };

  // useEffect hook to load redeem code from localStorage on component mount
  useEffect(() => {
    const savedRedeemCode = localStorage.getItem("redeemCode");
    if (savedRedeemCode) {
      setRedeemCode(savedRedeemCode);
    }
  }, []);

  // useEffect hook to save redeem code to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("redeemCode", redeemCode);
  }, [redeemCode]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("user"));
        if (!userData || !userData.id) {
          setError(
            "User ID not found. Please make sure the user is authenticated."
          );
          setIsLoggedIn(false);
          return;
        }
        setIsLoggedIn(true);
        const userId = userData.id;
        const response = await axios.get(
          `https://api.rudra.shivaksha.com/api/getAllCartItems/${userId}`
        );
        setCartItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching cart items:", error);
        setError("Error fetching cart items. Please try again later.");
      }
    };

    fetchData();
  }, [isLoggedIn]); // Trigger fetch data when isLoggedIn changes

  if (!isLoggedIn) {
    return (
      <div className="full-page-error">
        <div className="text-red-500 font-semibold mb-4 mt-12 p-10 rounded-md text-center">
          <p className="text-lg mx-auto" style={{ maxWidth: "800px" }}>
            {error}
          </p>
        </div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="h-screen flex justify-center items-center bg-gray-100">
        <div className="absolute inset-0 flex justify-center items-center">
          <img src={loader} alt="Loading..." />
        </div>
      </div>
    );
  }

  // Function to calculate redeem discount based on the total amount
  const calculateRedeemDiscount = (totalAmount) => {
    let newRedeemDiscount = 0;
    if (redeemCode.toUpperCase() === "SHIVAKSHA") {
      if (totalAmount < 2000) {
        // Apply 2% discount
        newRedeemDiscount = totalAmount * 0.05;
      } else {
        // Apply 3% discount
        newRedeemDiscount = totalAmount * 0.07;
      }
    }
    return newRedeemDiscount;
  };

  const newRedeemDiscount = calculateRedeemDiscount(totalAmount);

  const handleQuantityChange = async (id, change) => {
    try {
      const existingItemIndex = cartItems.findIndex(
        (item) => item.cart_id === id
      );
      if (existingItemIndex !== -1) {
        const updatedItems = [...cartItems];
        const existingItem = updatedItems[existingItemIndex];
        const newQuantity = Math.max(existingItem.quantity + change, 1);
        existingItem.quantity = newQuantity;
        setCartItems(updatedItems);
        await axios.put(
          `https://api.rudra.shivaksha.com/api/updateCartItemQuantity/${id}`,
          { quantity: newQuantity }
        );
      } else {
        console.error("Item not found in cart.");
      }
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  const handleDeleteItem = async (id) => {
    try {
      await axios.delete(
        `https://api.rudra.shivaksha.com/api/deleteCartItem/${id}`
      );
      setCartItems(cartItems.filter((item) => item.cart_id !== id));
    } catch (error) {
      console.error("Error deleting item:", error);
    }
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user ? user.id : null;
  // Check if userId is null or undefined
  if (!userId) {
    console.error("User ID not found in local storage");
    // Handle the error accordingly, e.g., redirect to login page or display an error message
    return;
  }

  const fetchProfileData = async () => {
    try {
      const response = await axios.get(
        `https://api.rudra.shivaksha.com/api/get-user-profile/${userId}`
      );
      setProfileData(response.data); // Update this line to set profileData properly
    } catch (error) {
      console.error("Error fetching user profile data:", error);
    }
  };

  const shipping = calculateShipping(totalAmount);
  //giving discount on the basis of total amount of purchased product
  let discountAmount = 0;
  if (totalAmount <= 5000) {
    discountAmount = totalAmount * 0.05;
  } else if (totalAmount > 5000 && totalAmount <= 10000) {
    discountAmount = totalAmount * 0.1;
  } else {
    discountAmount = totalAmount * 0.15;
  }

  let grandTotal = totalAmount + shipping - discountAmount;
  let wallet = grandTotal * 0.02;
  //function to handle wallet
  const handleUseWallet = (e) => {
    e.preventDefault();
    setWalletPopup(true);
  };

  const handleconfirmWallet = (e) => {
    e.preventDefault();
    setWalletPopup(false);
    setUseWallet(true);
  };
  const handleNotConfirmWallet = (e) => {
    e.preventDefault();
    setWalletPopup(false);
  };

  if (useWallet === true) {
    grandTotal = totalAmount + shipping - discountAmount - wallet;
    wallet = 0;
  } else {
    grandTotal = totalAmount + shipping - discountAmount;
  }

  // Function to get the selected payment method
  const getSelectedPaymentMethod = () => {
    // Implement your logic to get the selected payment method
    const paymentOptions = document.getElementsByName("payment");
    let selectedPaymentOption = "";
    paymentOptions.forEach((option) => {
      if (option.checked) {
        selectedPaymentOption = option.value;
      }
    });
    return selectedPaymentOption;
  };

  // Define the getCartItems function
  const getCartItems = async (userId) => {
    try {
      const response = await axios.get(
        `https://api.rudra.shivaksha.com/api/getAllCartItems/${userId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching cart items:", error);
      throw new Error("Failed to fetch cart items");
    }
  };

  const handleCheckout = async () => {
    try {
      // Fetch cart items
      const fetchedCartItems = await getCartItems(userId);

      // Check if the elements exist before accessing their values
      const name = document.getElementById("name");
      const email = document.getElementById("email");
      const number = document.getElementById("number");
      const address = document.getElementById("address");
      const streetFlat = document.getElementById("Street_FLat");
      const pinCode = document.getElementById("PinCode");

      if (name && email && number && address && streetFlat && pinCode) {
        // Elements exist, proceed with accessing their values
        const formData = new FormData();
        formData.append("name", name.value);
        formData.append("email", email.value);
        formData.append("number", number.value);
        formData.append("address", address.value);
        formData.append("Street_FLat", streetFlat.value);
        formData.append("PinCode", pinCode.value);
        // Append cart items to form data
        formData.append("cartItems", JSON.stringify(fetchedCartItems));

        // Send checkout request
        const response = await axios.post(
          "http://localhost:3006/api/create-order",
          formData
        );

        if (response.status === 200) {
          // Delete cart items after successful order creation
          await axios.delete(
            `https://api.rudra.shivaksha.com/api/deleteCartItems/${userId}`
          );
          console.log("Checkout response:", response.data);
        }
      } else {
        console.error("One or more elements not found in the DOM");
      }
    } catch (error) {
      console.error("Error during checkout:", error);
    }
  };

  return (
    <div className="cart-page-container mt-8">
      <form onSubmit={handleCheckout} className="mb-8">
        {/* Current Step Indicator */}
        <div className="text-xs text-gray-600 text-center">
          Step {currentStep} of {totalSteps}
        </div>

        {currentStep === 1 && (
          <div className="cart-items-container">
            <h2 className="section-heading">Cart Items</h2>
            <div className="cart-items-table">
              <table>
                <thead>
                  <tr>
                    <th>S.N</th>
                    <th>Image</th>
                    <th>Item Name</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {cartItems.map((item, index) => (
                    <tr key={item.cart_id}>
                      <td>{index + 1}</td> {/* Serial number starts from 1 */}
                      <td className="item-image">
                        <img
                          src={`https://api.rudra.shivaksha.com/${item.image_path}`}
                          alt=""
                        />
                      </td>
                      <td>{item.item_name}</td>
                      <td>{item.price}</td>
                      <td className="quantity-controls">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleQuantityChange(item.cart_id, -1);
                          }}
                        >
                          <AiOutlineMinus />
                        </button>
                        <span>{item.quantity}</span>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleQuantityChange(item.cart_id, 1);
                          }}
                        >
                          <IoMdAdd />
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDeleteItem(item.cart_id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className=" bg-slate-300 md:w-[40%] grid md:grid-flow-col md:grid-col-2 md:gap-2 mt-3 md:float-end rounded-md px-2 py-1">
                <div>
                  <p className=" font-semibold">
                    Total amount: INR {totalAmount}
                  </p>
                  <p className=" font-semibold">
                    Shipping amount: INR {shipping}
                  </p>
                  <p className=" font-semibold">
                    {" "}
                    Discount: INR {discountAmount}
                  </p>
                </div>
                <hr className=" md:hidden" />
                <div>
                  <div className=" font-semibold text-lg align-self-end">
                    Wallet amount: INR {wallet.toFixed(2)}
                  </div>
                  <div>
                    <button
                      className=" bg-gray-900 text-white text-lg py-[0.4rem] px-3 rounded-md active:ring-2 my-2 ring-black"
                      onClick={handleUseWallet}
                    >
                      Use wallet
                    </button>
                  </div>
                  <p className=" font-semibold text-xl">
                    {" "}
                    Grand Total: INR {grandTotal}
                  </p>
                </div>

                {/* wallet confirmation popup */}
                {walletPopup && (
                  <div className=" bg-slate-100 rounded-md shadow-md shadow-black fixed top-24 z-[9999] px-2 py-3">
                    <p className=" text-xl">
                      {" "}
                      Are you sure want to use your wallet amount?
                    </p>
                    <div className=" flex gap-2 justify-end mt-4">
                      <button
                        className=" bg-black text-white px-3 py-1 rounded-lg"
                        onClick={handleconfirmWallet}
                      >
                        Yes
                      </button>
                      <button
                        className=" bg-slate-300 text-black px-3 py-1 rounded-lg"
                        onClick={handleNotConfirmWallet}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </div>{" "}
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="cart-summary-container w-[40rem] mx-auto">
            <h2 className="section-heading">Customer Information</h2>
            <div className="mb-4">
              <div>
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="border border-gray-300 p-2 rounded w-full"
                  type="text"
                  id="name"
                  placeholder="Enter your name"
                  required // Add required attribute
                />
                {/* Conditional rendering of error message */}
                {error && !document.getElementById("name").value && (
                  <p className="text-red-500 text-sm mt-1">Name is required.</p>
                )}
              </div>
            </div>

            <div>
              {/* Email */}
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="border border-gray-300 p-2 rounded w-full"
                  type="email"
                  id="email"
                  placeholder="Enter your email"
                  required // Add required attribute
                />
              </div>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="number"
              >
                Number
              </label>
              <input
                className="border border-gray-300 p-2 rounded w-full"
                type="number"
                id="number"
                placeholder="Enter your number"
                required // Add required attribute
              />
            </div>

            {/* Shipping Address */}
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="address"
              >
                Shipping Address
              </label>
              <textarea
                className="border border-gray-300 p-2 rounded w-full"
                id="address"
                placeholder="Enter your shipping address"
                required // Add required attribute
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="address_Street"
              >
                Street / Flat
              </label>
              <textarea
                className="border border-gray-300 p-2 rounded w-full"
                id="Street_FLat"
                placeholder="Enter your Street/Flat Details"
              ></textarea>
            </div>

            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="PinCode"
              >
                Pin Code:
              </label>
              <textarea
                className="border border-gray-300 p-2 rounded w-full"
                id="PinCode"
                placeholder="Enter your Pin Code Number"
                required // Add required attribute
              ></textarea>
            </div>

            {/* <h2 className="section-heading">Payment Options</h2>
      
      <div className="mb-8">
      <div className="flex items-center">
          <input
            type="radio"
            id="Cash On Delivery"
            name="payment"
            value="CashOnDelivery"
            className="mr-2"
          />
          <label htmlFor="creditCard">Cash On Delivery</label>
        </div>
        <div className="flex items-center mb-4">
          <input
            type="radio"
            id="paypal"
            name="payment"
            value="paypal"
            className="mr-2"
          />
          <label htmlFor="paypal">PayPal</label>
          
        </div>
        <div className="flex items-center">
          <input
            type="radio"
            id="creditCard"
            name="payment"
            value="creditCard"
            className="mr-2"
          />
          <label htmlFor="creditCard">Credit Card</label>
        </div>

      </div> */}

            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2">Redeem Voucher</h2>
              <div className="flex items-center">
                <input
                  type="text"
                  value={redeemCode}
                  placeholder="Enter Your Redeem Code"
                  onChange={(e) => setRedeemCode(e.target.value)}
                  className="border border-gray-300 rounded-md py-2 px-4 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-500"
                />

                {/* Add button if needed */}
                <button
                  onClick={handleRedeemCode}
                  className="ml-2 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                >
                  Redeem
                </button>
              </div>
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
              {redeemDiscount > 0 && (
                <p className="text-green-500 text-sm mt-1">
                  Redeem Discount: {redeemDiscount.toFixed(2)}
                </p>
              )}
            </div>
          </div>
        )}
        {/* Step 3: Payment Method */}
        {currentStep === 3 && (
          <div className="payment-method-container">
            <h2 className="section-heading">Payment Method</h2>
            {/* Render payment method selection form here */}
            <div className="mb-8">
              <h3>Select Payment Method</h3>
              <div className="flex items-center mb-4">
                <input
                  type="radio"
                  id="creditCard"
                  name="payment"
                  value="creditCard"
                  className="mr-2"
                />
                <label htmlFor="creditCard">Credit Card</label>
              </div>
              <div className="flex items-center mb-4">
                <input
                  type="radio"
                  id="paypal"
                  name="payment"
                  value="paypal"
                  className="mr-2"
                />
                <label htmlFor="paypal">PayPal</label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="CashOnDelivery"
                  name="payment"
                  value="CashOnDelivery"
                  className="mr-2"
                />
                <label htmlFor="CashOnDelivery">Cash On Delivery</label>
              </div>
              {/* Add more payment options as needed */}
            </div>
          </div>
        )}
      </form>

      {/* Navigation Buttons */}
      <div className="flex justify-between mx-10">
        {currentStep > 1 && (
          <button
            type="button"
            onClick={handlePrev}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Previous
          </button>
        )}
        {currentStep < 3 && (
          <button
            type="button"
            onClick={handleNext}
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Next
          </button>
        )}
        {currentStep === 3 && (
          <button
            onClick={handleCheckout}
            type="submit"
            className="checkout-button"
          >
            Check Out
          </button>
        )}
      </div>
    </div>
  );
};

export default CartPage;
