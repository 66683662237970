
// import React from "react";
// import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
// import { Link } from "react-router-dom"; // Import Link for routing
// import "./CarouselSlider.css";

// export default function CarouselSlider() {
//   return (
//     <MDBCarousel showControls showIndicators className="my-carousel">
//       <MDBCarouselItem itemId={1}>
//         <div className="carousel-item-content">
//           <a href="#">
//             <img
//               src="http://vedicrudraksha.net/assets/ipanel/uploads/banner/Banner_3.jpg"
//               className="d-block w-100 carousel-image"
//               alt="..."
//             />
//           </a>
//           <Link to="/ProductPage" className="shop-button">
//             Shop
//           </Link>
//         </div>
//       </MDBCarouselItem>
//       <MDBCarouselItem itemId={2}>
//         <div className="carousel-item-content">
//           <a href="ProductPage">
//             <img
//               src="https://www.whiteauravastustore.com/wp-content/uploads/2021/01/rudraksha-banner.jpg"
//               className="d-block w-100 carousel-image"
//               alt="..."
//             />
//           </a>
//           <Link to="/ProductPage" className="shop-button">
//             Shop
//           </Link>
//         </div>
//       </MDBCarouselItem>
//       <MDBCarouselItem itemId={3}>
//         <div className="carousel-item-content">
//           <a href="#">
//             <img
//               src="https://www.astromantra.com/wp-content/uploads/2016/07/13-Mukhi-Rudraksha-Banner-Astromantra.jpg"
//               className="d-block w-100 carousel-image"
//               alt="..."
//             />
//           </a>
//           <Link to="/ProductPage" className="shop-button">
//             Shop
//           </Link>
//         </div>
//       </MDBCarouselItem>
//     </MDBCarousel>
//   );
// }

  import React from 'react';
  import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';
  import { Link } from 'react-router-dom'; // Import Link for routing
  import astromiteshvideo from '../../Videos/Unlock Your Future with Astro Mitesh.gif'
 
  import './CarouselSlider.css';

  export default function CarouselSlider() {
    return (
      <MDBCarousel showControls showIndicators className="my-carousel">
        <MDBCarouselItem itemId={1}>
          <div className="carousel-item-content">
            <a href="#">
              <img src='http://vedicrudraksha.net/assets/ipanel/uploads/banner/Banner_3.jpg' className='d-block w-100 carousel-image' alt='...' />
            </a>
            <Link to="/ProductPage" className="shop-button">Shop</Link>
          </div>
        </MDBCarouselItem>
        <MDBCarouselItem itemId={2}>
          <div className="carousel-item-content">
            <a href="ProductPage">
              <img src='https://www.whiteauravastustore.com/wp-content/uploads/2021/01/rudraksha-banner.jpg' className='d-block w-100 carousel-image' alt='...' />
            </a>
            <Link to="/ProductPage" className="shop-button">Shop</Link>
          </div>
        </MDBCarouselItem>
        <MDBCarouselItem itemId={3}>
          <div className="carousel-item-content">
            <a href="#">
              {/* <img src='file:///D:/hngemsandrudrakshaplace-ecommerce/src/Videos/Unlock%20Your%20Future%20with%20Astro%20Mitesh.gif' className='d-block w-100 carousel-image' alt='...' /> */}
              <img src={astromiteshvideo} className='d-block w-100 carousel-image mt-2' alt='...' />
            </a>
            <Link to="/astromitesh" className="shop-button">Unlock Now</Link>
          </div>
        </MDBCarouselItem>
      </MDBCarousel>
    );
  }

