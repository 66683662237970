import React, { useState, useEffect } from "react";
import axios from "axios";
import "./App.css";
import { Route, Routes } from "react-router-dom";

import Navbar from "./Component/Navigation/Navbar";
import Home from "./Component/Home/Home";
import UserProfile from "./Component/UserAccount/UserProfile";
import UserProfileForm from "./Component/UserAccount/UserProfileForm";
import Rudraksha from "./Component/Product/Categories/Rudraksha";
import Stones from "./Component/Product/Categories/Stones";
import CartPage from "./Component/Product/CartPage";
import ShoppingPage from "./Component/Product/ShoppingPage";
import ProductDetail from "./Component/Product/ProductDetail";
import TranslationComponent from "./Component/TranslationComponent";
import ProductCertificated from "./Component/Product/ProductCertificated";
import Subnav from "./Component/Navigation/Subnav";
import LogIn from "./Component/User Auth/LogIn";
import Signup from "./Component/User Auth/Signup";
import ShowBlog from "./Component/Blog/ShowBlog";
import GetInTouch from "./Component/Home/GetInTouch";
import Cart from "./Component/Product/Cart";
import AddNewProduct from "./Component/Product/AddNewProduct";
import ProductCategory from "./Component/Product/ProductCategory";
import ProductCard from "./Component/Product/ProductCard";
import ProductPage from "./Component/Product/ProductPage";
import FloatingCartButton from "./Component/Cart/FloatingCartButton";
import AboutUs from "./Admin/AboutUs";

import ForgotPassword from './Component/User Auth/ForgotPassword';

import Wishlist from "./Component/Navigation/WishList";
import NotificationBar from "./Component/Navigation/NotificationBar";
import OrderHistory from "./Component/UserAccount/OrderHistory";
import OrderReturnHistory from "./Component/UserAccount/OrderReturnHistory";
import Footer from "./Component/Footer/Footer";
import Crystal from "./Component/Product/Categories/Crystal";
import BlogDetails from "./Component/Blog/BlogDetails";
import BlogPost from "./Admin/BlogPost";
import Mala from "./Component/Product/Categories/Mala";
import Bracelet from "./Component/Product/Categories/Bracelet";
import AstroMitesh from "./Admin/AstroMitesh";
import CertificateDetail from "./Component/Product/CertificateDetail";
import VideoCall from "./Admin/VideoCall";


const App = () => {
  //qrcode data fetching
  const [idFromQR, setIdFromQR] = useState(null);

  const [cartCount, setCartCount] = useState(0); // State for cart count

  const fetchCartCount = async () => {
    try {
      const userData = JSON.parse(localStorage.getItem("user"));
      if (!userData || !userData.id) {
        console.error(
          "User ID not found. Please make sure the user is authenticated."
        );
        setCartCount(0);
        return;
      }

      const userId = userData.id;
      const response = await axios.get(
        `https://api.rudra.shivaksha.com/api/getAllCartItems/${userId}`
      );
      const cartItems = response.data;
      const itemCount = cartItems.length;

      setCartCount(itemCount);
    } catch (error) {
      console.error("Error fetching cart count:", error);
    }
  };

  useEffect(() => {
    // Fetch cart count initially
    fetchCartCount();

    // Refresh cart count every 2 seconds
    const intervalId = setInterval(fetchCartCount, 2000);

    // Clear interval on component unmount or when the dependency changes
    return () => clearInterval(intervalId);
  }, []); // Fetch cart count on component mount

  // Callback function to receive id from QRComponent
  const handleIdFromQR = (id) => {
    setIdFromQR(id);
  };

  const routeElements = {
    "/": <Home />,
    "/user-account": <UserProfile />,
    "/Login": <LogIn />,
    "/Sign-up": <Signup />,
    "/CustomerInfo": <UserProfileForm />,
    "/Cat-Rudraksha": <Rudraksha />,
    "/Cat-Stones": <Stones />,
    "/CartPage": <CartPage />,
    "/ShoppingPage": <ShoppingPage />,
    "/product/:productId": <ProductDetail />,
    "/blog/:postId": <BlogDetails />,
    "/blogpost": <BlogPost />,
    "/abc": <TranslationComponent />,
    "/productcert": <ProductCertificated />,
    "/productcert/:productId": <ProductCertificated />,
    "/blog": <ShowBlog />,
    "/GetInTouch": <GetInTouch />,

    "/ProductPage" :<ProductPage/>,
    "/About-us" :<AboutUs/>,
    "/wishlist" :<Wishlist/>,
    "/orderh" :<OrderReturnHistory/>,
    "/category/Crystal" :<Crystal/>,
    "/category/Mala" :<Mala/>,
    "/category/Bracelet" :<Bracelet/>,
    "/astromitesh" :<AstroMitesh/>,
    "/forgotPassword" :<ForgotPassword/>,
    "/videoCall" :<VideoCall/>,


  };

  return (
    <>
      <NotificationBar />
      {/* <Navbar /> */}
      <FloatingCartButton cartCount={cartCount} />
      <Subnav />
      <Routes>
        {Object.entries(routeElements).map(([path, element]) => (
          <Route key={path} path={path} element={element} />
        ))}
        <Route
         path="/certificateDetail/:productId"
         element={<CertificateDetail handleIdFromQR={handleIdFromQR} />}
        />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
