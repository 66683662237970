import React from 'react';

const ServicesSection = () => {
  const rudrakshaTextStyle = {
    color: '#761b0c', // Rudraksha color
    fontStyle: 'italic', // Add italic style
    fontWeight: 'bold', // Add bold style
  };

  const titleStyle = {
    fontSize: '28px',
    color: '#761b0c', // Change color as needed
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Add text shadow
  };

  const titleStyle2 = {
    fontSize: '18px',
    color: '#761b0c', // Change color as needed
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', // Add text shadow
  };

  return (
    <section className="py-5 py-xl-8">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 col-xl-7">
            <h2 className="mb-4 display-5 text-center" style={titleStyle}>
              Services for <span style={rudrakshaTextStyle}>Rudraksha</span> & Spiritual Items
            </h2>
            <p className="text-secondary mb-5 text-center">
              We strive to provide the best service and products to our customers. Here are some reasons to choose us:
            </p>
            <hr className="w-50 mx-auto mb-5 mb-xl-9 border-dark-subtle" />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row gy-5">
          {/* Service 1 */}
          <ServiceItem
            iconClass="bi bi-gem"
            title={<span style={rudrakshaTextStyle}>Authentic Rudraksha Beads</span>}
            description={(
              <p>
                Discover our wide range of authentic <span style={titleStyle2}>Rudraksha beads</span>, known for their spiritual significance and healing properties.
              </p>
            )}
          />

          {/* Service 2 */}
          <ServiceItem
            iconClass="bi bi-gem"
            title={<span style={rudrakshaTextStyle}>Spiritual Accessories</span>}
            description="Explore our collection of spiritual accessories including malas, yantras, idols, and more to enhance your spiritual journey."
          />

          {/* Service 3 */}
          <ServiceItem
            iconClass="bi bi-gem"
            title={<span style={rudrakshaTextStyle}>Spiritual Healing</span>}
            description="Experience holistic healing with our spiritual practices and rituals tailored to promote physical, mental, and emotional well-being."
          />

          {/* Service 4 */}
          <ServiceItem
            iconClass="bi bi-gem"
            title={<span style={rudrakshaTextStyle}>Spiritual Guidance</span>}
            description="Receive personalized spiritual guidance and support from our experienced practitioners to navigate your spiritual path."
          />

          {/* Service 5 */}
          <ServiceItem
            iconClass="bi bi-gem"
            title={<span style={rudrakshaTextStyle}>Divine Blessings</span>}
            description="Invoke divine blessings and positive energies into your life with our sacred rituals and ceremonies performed by our skilled priests."
          />
        </div>
      </div>
    </section>
  );
};

const ServiceItem = ({ iconClass, title, description }) => {
  return (
    <div className="col-12 col-md-6 col-lg-4">
      <div className="text-center px-md-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className={`bi ${iconClass} text-primary mb-4`} viewBox="0 0 16 16">
          <path d="..."/>
        </svg>
        <h5 className="m-2">{title}</h5>
        <p className="m-0 text-secondary">{description}</p>
      </div>
    </div>
  );
};

export default ServicesSection;
