import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

// Keyframes for CSS animations
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled components for animations
const CategorySection = styled.section`
  margin-bottom: 5vw;
  margin-top: 3vw;
  margin-left: 3rem;
  margin-right: 3rem;
`;

const CategoryTitle = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
  animation: ${fadeInUp} 1s ease forwards;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const CategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 3vw;
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr); /* Show two categories per row on small screens */
    gap: 1rem; /* Adjust gap for better spacing */
  }
`;

const CategoryCard = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
  animation: ${fadeInUp} 1s ease forwards;

  &:hover {
    transform: translateY(-5px);
  }
`;

const CategoryImage = styled.img`
  width: 100%;
  height: 200px; /* Set the height of the images */
  object-fit: cover; /* Ensure images maintain aspect ratio */
  border-radius: 10px 10px 0 0;
  &:hover {
    transform: translateY(-5px);
  }
`;

const CategoryLink = styled(Link)`
  text-decoration: none;
  color: #333;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Ensure the link takes up the entire height of the card */
`;

const CategoryName = styled.div`
  text-align: center;
  padding: 10px 0;
`;

function ProductCategory() {
  const categories = [
    { name: 'Crystal', image: 'https://cdn.pixabay.com/photo/2017/09/06/21/43/crystal-2723145_640.jpg' },
    { name: 'Mala', image: 'https://thebombaystore.com/cdn/shop/products/1_0497f4f6-55d1-498e-800f-0ca2e641b893.jpg?v=1633613555' },
    { name: 'Bracelet', image: 'https://5.imimg.com/data5/SELLER/Default/2023/4/299435322/AU/JA/VY/40078231/7-mukhi-nepal-rudraksha-beads-bracelet-design-iii-500x500.jpg' },
    { name: 'Silver-Bracelet-Mala', image: 'https://images.meesho.com/images/products/272013910/c6igu_512.webp' },
    { name: 'Rudraksha-Nepali-Beads', image: 'https://www.rudrablessings.com/cdn/shop/products/nep-7m-loose-main-1_1500x.jpg?v=1594935261' },
    { name: 'Rudraksha-Indonesian-Beads', image: 'https://i0.wp.com/rudrajyoothi.com/wp-content/uploads/2020/09/2-mukhi-indonesia.jpeg?resize=512%2C512&ssl=1' },
    { name: 'Sphatik-Murti', image: 'https://m.media-amazon.com/images/I/41hbqyvm5nL._AC_UF894,1000_QL80_.jpg' },
    { name: 'Parad-Murti', image: 'https://i.pinimg.com/564x/ff/27/93/ff27936ce9d32e2e91ca5d641faddbea.jpg' },
    { name: 'Metal Murti', image: 'https://5.imimg.com/data5/SELLER/Default/2022/6/IT/NV/YY/121518/main23071-500x500.jpg' },
  ];

  return (
    <CategorySection>
      <CategoryTitle>Our Catalog</CategoryTitle>
      <CategoryGrid>
        {categories.map((category, index) => (
          <CategoryCard key={index}>
            <CategoryLink to={`/category/${category.name}`}>
              <CategoryImage src={category.image} alt={category.name} />
              <CategoryName>{category.name}</CategoryName>
            </CategoryLink>
          </CategoryCard>
        ))}
      </CategoryGrid>
    </CategorySection>
  );
}

export default ProductCategory;
