import React, { useState, useEffect } from 'react';
import ProductCard from '../ProductCard';

function Rudraksha() {
  const [allProducts, setAllProducts] = useState([]);
  const [rudrakshaProducts, setRudrakshaProducts] = useState([]);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  const fetchAllProducts = async () => {
    try {
      const response = await fetch('https://api.rudra.shivaksha.com/api/products');
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      console.log('Fetched products:', data.products); // Log fetched products
      setAllProducts(data.products || []); // Ensure allProducts is initialized as an array
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    console.log('All products:', allProducts); // Log allProducts
    const rudrakshaItems = allProducts.filter(product => product.category === 'Rudraksha Product');
    console.log('Rudraksha products:', rudrakshaItems); // Log filtered Rudraksha products
    setRudrakshaProducts(rudrakshaItems);
  }, [allProducts]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {rudrakshaProducts.map(product => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
}

export default Rudraksha;
