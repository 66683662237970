import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaShoppingCart, FaHeart, FaArrowRight } from "react-icons/fa";

function ProductCard({
  product,
  handleToggleCart,
  handleToggleWishlist,
  openPopup,
}) {
  const {
    id,
    item_name: name,
    prices: price,
    image_path: imagePath,
    addedToCart,
  } = product;

  return (
    <div className="border rounded-md overflow-hidden shadow-md relative h-85 transition duration-300 ease-in-out transform hover:scale-105">
      <img
        src={`https://api.rudra.shivaksha.com/${imagePath}`}
        alt={name}
        className="w-full h-48 object-cover"
      />
      <div className="px-4 py-2">
        <p className="text-lg font-semibold mb-2">{name}</p>
        <p className="text-gray-600 mb-2">{/* Add category if needed */}</p>
        <p className="text-gray-800 font-semibold">${price}</p>
      </div>
      <div className="flex items-center justify-between px-4 pb-4">
        <button
          onClick={() => handleToggleCart(id)}
          disabled={addedToCart} // Disable button if product is already in cart
          className={`flex items-center gap-1 rounded-md px-2 py-[0.25rem] text-sm active:ring-2 active:ring-slate-500 ${
            addedToCart
              ? "bg-red-800 cursor-not-allowed"
              : "bg-red-800 text-white"
          } hover:bg-red-900`}
        >
          <FaShoppingCart />{" "}
          <span className="hidden sm:inline">
            {addedToCart ? "Added" : "Add to Cart"}
          </span>
        </button>

        <button
          onClick={() => handleToggleWishlist(id)}
          disabled={addedToCart} // Disable button if product is already in wishlist
          className={`flex items-center gap-1 rounded-md px-2 py-[0.25rem] text-sm active:ring-2 active:ring-slate-500 ${
            addedToCart
              ? "bg-red-800 cursor-not-allowed"
              : "bg-red-800 text-white"
          } hover:bg-red-900`}
        >
          <FaHeart />{" "}
          <span className="hidden sm:inline">
            {addedToCart ? "Added" : "Add to Wishlist"}
          </span>
        </button>

        <button onClick={() => openPopup(product)} className="text-sm">
          <div
            className={`bg-zinc-900 rounded-md text-white px-2 py-[0.25rem] sm:px-3 sm:py-[0.4rem] bg-red-800 text-white hover:bg-red-900`}
          >
            <FaArrowRight />
          </div>
        </button>
      </div>
    </div>
  );
}

export default ProductCard;
