import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Signup = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: ""
  });

  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    try {
      const { username, email, password } = formData;
      // Send the confirmation voucher code request
      await axios.post("http://localhost:3006/api/confirmation-voucher/send", { email });

      // Show confirmation popup
      setShowConfirmationPopup(true);
      setErrorMessage(""); // Clear any previous error messages
    } catch (error) {
      console.error("Error sending confirmation voucher code:", error);
      setErrorMessage("Error sending confirmation voucher code. Please try again.");
    }
  };

  const handleConfirmationSubmit = async (e) => {
    e.preventDefault();
    try {
      const { username, email, password, confirmationCode } = formData;
  
      // Check if confirmation code is valid
      const confirmationCheckResponse = await axios.post("http://localhost:3006/api/confirmation-voucher/check", {
        email,
        confirmationCode
      });
  
      // If confirmation code is valid, proceed with signup
      if (confirmationCheckResponse.status === 200) {
        // Send user signup request
        const response = await axios.post("https://api.rudra.shivaksha.com/api/users/signup", {
          username,
          email,
          password,
          confirmationCode
        });
  
        // If signup is successful, clear form data and close confirmation popup
        setFormData({
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
          confirmationCode: "" // Clear confirmation code after signup
        });
        setShowConfirmationPopup(false);
        setErrorMessage(""); // Clear any previous error messages
        // You can perform additional actions here if needed
        console.log(response.data);
      } else {
        // If confirmation code is not valid, show error message
        setErrorMessage("Incorrect confirmation code");
      }
    } catch (error) {
      console.error("Error signing up:", error);
      setErrorMessage("Error signing up. Please try again."); // Set error message for signup failure
    }
  };
  
  

  return (
    <div className="container mx-auto px-4 md:px-0">
      <div className="rounded-md shadow-md shadow-slate-400 bg-slate-50 mx-auto my-5 md:w-[35%]">
        <div className="text-2xl font-semibold text-center mt-2">Sign Up</div>
        <form className="grid grid-cols-1 gap-4 px-5 py-3" onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              id="username"
              className="border-[1px] border-slate-500 rounded-md p-2"
              onChange={handleChange}
              value={formData.username}
            />
          </div>

          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              className="border-[1px] border-slate-500 rounded-md p-2"
              onChange={handleChange}
              value={formData.email}
            />
          </div>

          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              className="border-[1px] border-slate-500 rounded-md p-2"
              onChange={handleChange}
              value={formData.password}
            />
          </div>

          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              className="border-[1px] border-slate-500 rounded-md p-2"
              onChange={handleChange}
              value={formData.confirmPassword}
            />
          </div>

          {errorMessage && (
            <div className="text-red-600 text-center">{errorMessage}</div>
          )}

          <button className="bg-black text-white px-4 py-2 text-sm rounded-md w-full active:ring-2 active:ring-slate-500">
            Sign Up
          </button>

          <div className="text-center">
            Already have an account? <Link to="/Login" className="underline text-slate-600">Sign in</Link>
          </div>
        </form>
      </div>

      {showConfirmationPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-8 rounded-md relative">
            <button
              className="absolute top-0 right-0 m-3 text-gray-600 hover:text-gray-800"
              onClick={() => setShowConfirmationPopup(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <h2 className="text-2xl font-semibold">Please Enter Confirmation Code</h2>
            <form onSubmit={handleConfirmationSubmit}>
              <input
                type="text"
                value={formData.confirmationCode}
                onChange={(e) => setFormData({ ...formData, confirmationCode: e.target.value })}
                maxLength={6}
                className="w-full p-2 border border-gray-300 rounded-md mb-4"
              />
              {errorMessage && (
            <div className="text-red-600 text-center">{errorMessage}</div>
          )}  
              <button className="bg-black text-white px-4 py-2 text-sm rounded-md w-full">
                Confirm
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Signup;
