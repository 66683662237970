import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons"; // Updated import statement
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const YoutubePost = () => {
  const [videoLinks, setVideoLinks] = useState([]);
  const YoutubePostContainer = styled.div`
  margin-top: 40px; /* Adjust margin as needed */
`;
  useEffect(() => {
    // Simulating fetching video links from an API or any other source
    const fetchedVideoLinks = [
      "https://www.youtube.com/embed/3Osjw0tonxg",
      "https://www.youtube.com/embed/pKvpRnPpAGQ",
      "https://www.youtube.com/embed/8EQXHVt0ZQg",
      "https://www.youtube.com/embed/huMThe59djc",
      "https://www.youtube.com/embed/NAnFFtQZ_h8",
      "https://www.youtube.com/embed/iQHV_CUrQ0o",
      "https://www.youtube.com/embed/Npu1ZeTMIr8",
      "https://www.youtube.com/embed/5BgbOwZYZhA",
    ];
    setVideoLinks(fetchedVideoLinks);
  }, []);

  const PrevArrow = (props) => {
    return (
      <div
        className=" text-[#761b0c] border-[#761b0c] border-2 rounded-xl px-3 absolute right-[7rem] top-[-2.3rem] md:top-[-3rem]  hover:bg-[#761b0c] hover:rounded-md hover:text-white w-fit font-semibold text-xl cursor-pointer"
        onClick={props.onClick}
      >
        &larr;
      </div>
    );
  };

  const NextArrow = (props) => {
    return (
      <div
        className=" text-[#761b0c] border-[#761b0c] border-2 rounded-xl px-3 absolute right-[3rem] top-[-2.3rem] md:top-[-3rem] hover:bg-[#761b0c] hover:rounded-md hover:text-white w-fit font-semibold text-xl cursor-pointer"
        onClick={props.onClick}
      >
        &rarr;
      </div>
    );
  };
  var settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <YoutubePostContainer>
    <div className="md:text-center text-[#761b0c] ">
      <h1 className="post-title">
        Follow us on{" "}
        <FontAwesomeIcon icon={faYoutube} className="youtube-icon" /> YouTube
      </h1>
      <div className="youtube-link-container">
        <a
          href="https://www.youtube.com/channel/yourchannel"
          target="_blank"
          rel="noopener noreferrer"
          className="youtube-link text-[#761b0c] text-2xl font-semibold no-underline"
        >
          Stay Connected
        </a>
      </div>
    </div>
    <Slider {...settings}>
      {videoLinks.map((link, index) => (
        <div key={index} className="video-card mx-auto">
          <iframe
            src={link}
            // width="476"
            // height="591"
            frameBorder="0"
            allowFullScreen="true"
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            title={`video-${index}`}
            className="w-[400px] h-[591px] xl:w-[476px] rounded-lg"
          ></iframe>
        </div>
      ))}
    </Slider>
  </YoutubePostContainer>
);
};

export default YoutubePost;