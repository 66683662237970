import React, { useState, useEffect } from 'react';
import './FloatingCartButton.css';
import Cart from '../Product/Cart';
import img1 from '../../photos/7141107.webp';

function FloatingCartButton({ cartCount }) {
  const [cartVisible, setCartVisible] = useState(false);
  const [updatedCartCount, setUpdatedCartCount] = useState(cartCount);

  // Toggle cart visibility
  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };

  // Update cart count every 2 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      setUpdatedCartCount(cartCount);
    }, 2000); // Refresh every 2 seconds

    // Clear the interval when the component is unmounted or dependencies change
    return () => clearInterval(timer);
  }, [cartCount]);

  return (
    <div className="cart-icon-container">
      <div className="cart-icon" onClick={toggleCart}>
        <img src={img1} alt="Cart" />
        {/* Display the updated cart count */}
        {updatedCartCount > 0 && <div className="cart-count">{updatedCartCount}</div>}
      </div>
      {cartVisible && <Cart onClose={toggleCart} />}
    </div>
  );
}

export default FloatingCartButton;
