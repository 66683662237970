import React, { useEffect } from 'react';

const TranslationComponent = () => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl + Shift + T is pressed
      if (event.ctrlKey && event.shiftKey && event.keyCode === 84) {
        // Open popup for changing language
        alert('Popup for changing language will be displayed here');
        event.preventDefault(); // Prevent default browser behavior
      }
    };

    // Add event listener for keydown
    document.addEventListener('keydown', handleKeyDown);

    // Load Google Translate script
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.body.appendChild(script);

    // Define Google Translate callback function
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'hi', 
        includedLanguages: 'en,fr,es,hi,ne,gu,ta,ml', // Add or remove languages as needed
        layout: window.google.translate.TranslateElement.InlineLayout.HORIZONTAL, // Use Horizontal layout
      }, 'google_translate_element');
    };

    // Cleanup
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      delete window.googleTranslateElementInit;
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div id="google_translate_element"></div>
  );
};

export default TranslationComponent;
